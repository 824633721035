import Cookies from 'universal-cookie';
import {jwtDecode} from "jwt-decode";

const cookies = new Cookies();

export const useAuthCookie = () => {
    const accessToken = cookies.get('access-token');
    return !!accessToken;
};

export const useUserId = () => {
    const token = cookies.get('access-token');
    if (!token) {
        return null;
    }
    const decodedToken = jwtDecode(token);
    const id = decodedToken.id;
    return id;
};

export const useUserNIE = () => {
    const token = cookies.get('access-token');
    if (!token) {
        return null;
    }
    const decodedToken = jwtDecode(token);
    const NIE = decodedToken.NIE;
    return NIE;
};

export const useUserRole = () => {
    const token = cookies.get('access-token');
    if (!token) {
        return null;
    }
    const decodedToken = jwtDecode(token);
    const role = decodedToken.role;
    return role;
};

export const useUser = () => {
    const token = cookies.get('access-token');
    if (!token) {
        return null;
    }
    const decodedToken = jwtDecode(token);
    return decodedToken;
};