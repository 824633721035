import './App.css';
import Navbar from '../src/components/Navbar';
import Footer from '../src/components/Footer';
import MainPage from '../src/pages/MainPage';
import Contact from '../src/pages/Contact';
import Login from '../src/pages/Login';
import Signin from '../src/pages/Signin';
import AppHomePage from './pages/AppHomePage';
import PrivateRoute from './utils/PrivateRoute';
import NotFoundPage from './pages/NotFoundPage';
import AppNavbar from '../src/components/AppNavbar';
import Agendar from '../src/pages/Agendar';
import Record from '../src/pages/Record';
import AppointmentManage from '../src/pages/AppointmentManage';
import Perfil from './pages/Perfil';
import Calendario from './pages/Calendar';
import DateAppointments from './pages/DateAppointments';
import DentistManage from './pages/DentistManage';
import ClientsManage from './pages/ClientsManage';
import DentistsAdminManage from './pages/DentistsAdminManage';
import IndividualDentistManage from './pages/IndividualDentistManage';
import Signindentists from './pages/Signindentists';
import AppointmentsManageAssist from './pages/AppointmentsManageAssist';
import AnamnesisCreation from './pages/AnamnesisCreation';
import AnamnesisEdit from './pages/AnamnesisEdit';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactWhatsappButton from 'react-whatsapp-button';
import { useUserRole } from './utils/Cookies';
import AnamnesisInspect from './pages/AnamnesisInspect';
import RecordDentist from './pages/RecordDentist';
import ValoracionCreation from './pages/ValoracionCreation';
import ValoracionView from './pages/ValoracionView';
import ValoracionEdit from './pages/ValoracionEdit';
import PresupuestoCreate from './pages/PresupuestoCreate';
import PresupuestosInspect from './pages/PresupuestosInspect';
import UserCheck from './pages/UserCheck';
import DarCitaConUser from './pages/DarCitaConUser';
import SigninNoUser from './pages/SigninNoUser';
import LoginDoc from './pages/LoginDoc';
import NieForEmail from './pages/NieForEmail';
import ResetPassword from './pages/ResetPassword';
import PasswordSet from './pages/PasswordSet';
import Contaduria from './pages/Contaduria';
import Ingresos from './pages/Ingresos';
import Egresos from './pages/Egresos';
import AdminManage from './pages/AdminManage';
import SecreAdminManage from './pages/SecreAdminManage';
import Signinsecretarias from './pages/Signinsecretarias';
import IndividuaSecreManage from './pages/IndividuaSecreManage';
import AdminInspect from './pages/AdminInspect';
import Procedimientos from './pages/Procedimientos';

function App() {
    const role = useUserRole();
    const message = "¡Hola SmileUp! necesito ayuda";

    return (
        <Router>
            <div className="App">
                {role === 'cliente' && (
                    <ReactWhatsappButton
                        message={message}
                        countryCode="34"
                        phoneNumber="681887047"
                        animated
                    />
                )}
                <AppNavbar />
                <Routes>
                    <Route
                        path="/"
                        element={<RenderWithPageFooter component={MainPage} />}
                    />
                    <Route
                        path="/contacto"
                        element={<RenderWithPageFooter component={Contact} />}
                    />
                    <Route
                        path="/login"
                        element={<RenderWithoutPageFooter component={Login} />}
                    />
                    <Route
                        path="/logindoc"
                        element={<RenderWithoutPageFooter component={LoginDoc} />}
                    />
                    <Route
                        path="/signin"
                        element={<RenderWithoutPageFooter component={Signin} />}
                    />
                    <Route
                        path="/recover"
                        element={<RenderWithoutPageFooter component={NieForEmail} />}
                    />
                    <Route
                        path="/establecer"
                        element={<RenderWithoutPageFooter component={PasswordSet} />}
                    />
                    <Route
                        path="/app/reset-password"
                        element={<RenderWithoutPageFooter component={ResetPassword} />}
                    />
                    <Route
                        path="/app"
                        element={<PrivateRoute children={<AppHomePage />} />}
                    />

                    {/* Cliente */}
                    <Route
                        path="/app/cliente/agendar"
                        element={<PrivateRoute children={<Agendar />} />}
                    />
                    <Route
                        path="/app/cliente/historial"
                        element={<PrivateRoute children={<Record />} />}
                    />
                    <Route
                        path="/app/cliente/citas"
                        element={<PrivateRoute children={<AppointmentManage />} />}
                    />
                    <Route
                        path="/app/:role/anamnesis/:id"
                        element={<PrivateRoute children={<AnamnesisInspect />} />}
                    />
                    <Route
                        path="/app/:role/valoracion/:id"
                        element={<PrivateRoute children={<ValoracionView />} />}
                    />
                    <Route
                        path="/app/:role/presupuesto/:id"
                        element={<PrivateRoute children={<PresupuestosInspect />} />}
                    />

                    {/* Dentista */}
                    <Route
                        path="/app/:role/historial"
                        element={<PrivateRoute children={<RecordDentist />} />}
                    />
                    <Route
                        path="/app/dentista/gestionar"
                        element={<PrivateRoute children={<DentistManage />} />}
                    />
                    <Route
                        path="/app/:role/crear-valoracion/:id"
                        element={<PrivateRoute children={<ValoracionCreation />} />}
                    />
                    <Route
                        path="/app/:role/editar-valoracion/:id"
                        element={<PrivateRoute children={<ValoracionEdit />} />}
                    />
                    <Route
                        path="/app/:role/presupuesto/crear/:id/:presupuestoIndex"
                        element={<PrivateRoute children={<PresupuestoCreate />} />}
                    />
                    <Route
                        path="/app/:role/presupuesto/editar/:id/:presupuestoIndex"
                        element={<PrivateRoute children={<PresupuestoCreate />} />}
                    />
                    {/* Admin */}
                    <Route
                        path="/app/admin/clientes"
                        element={<PrivateRoute children={<ClientsManage />} />}
                    />
                    <Route
                        path="/app/admin/procedimientos"
                        element={<PrivateRoute children={<Procedimientos />} />}
                    />
                    <Route
                        path="/app/admin/dentistas"
                        element={<PrivateRoute children={<DentistsAdminManage />} />}
                    />
                    <Route
                        path="/app/admin/dentistas/:id"
                        element={<PrivateRoute children={<IndividualDentistManage />} />}
                    />
                    <Route
                        path="/app/admin/dentistas/signin"
                        element={<PrivateRoute children={<Signindentists />} />}
                    />
                    <Route
                        path="/app/admin/secretarios/signin"
                        element={<PrivateRoute children={<Signinsecretarias />} />}
                    />
                    <Route
                        path="/app/admin/contaduria"
                        element={<PrivateRoute children={<Contaduria />} />}
                    />
                    <Route
                        path="/app/admin/contaduria/ingresos"
                        element={<PrivateRoute children={<Ingresos />} />}
                    />
                    <Route
                        path="/app/admin/contaduria/egresos"
                        element={<PrivateRoute children={<Egresos />} />}
                    />
                    <Route
                        path="/app/admin/gestionar"
                        element={<PrivateRoute children={<AdminManage />} />}
                    />
                    <Route
                        path="/app/admin/inspeccionar"
                        element={<PrivateRoute children={<AdminInspect />} />}
                    />
                    <Route
                        path="/app/admin/secretarios"
                        element={<PrivateRoute children={<SecreAdminManage />} />}
                    />
                    <Route
                        path="/app/admin/secretarios/:id"
                        element={<PrivateRoute children={<IndividuaSecreManage />} />}
                    />

                    {/* secretaria */}
                    <Route
                        path="/app/secretaria/citas"
                        element={<PrivateRoute children={<AppointmentsManageAssist />} />}
                    />
                    <Route
                        path="/app/secretaria/crear-anamnesis/:id"
                        element={<PrivateRoute children={<AnamnesisCreation />} />}
                    />
                    <Route
                        path="/app/secretaria/editar-anamnesis/:id"
                        element={<PrivateRoute children={<AnamnesisEdit />} />}
                    />
                    <Route
                        path="/app/secretaria/verificar"
                        element={<PrivateRoute children={<UserCheck />} />}
                    />
                    <Route
                        path="/app/secretaria/cita/user/:id"
                        element={<PrivateRoute children={<DarCitaConUser />} />}
                    />
                    <Route
                        path="/app/secretaria/cita/nouser"
                        element={<PrivateRoute children={<SigninNoUser />} />}
                    />

                    {/* dentista, admin y secretaria */}
                    <Route
                        path="/app/:role/calendario"
                        element={<PrivateRoute children={<Calendario />} />}
                    />
                    <Route
                        path="/app/:role/calendario/:dia"
                        element={<PrivateRoute children={<DateAppointments />} />}
                    />

                    <Route
                        path="*"
                        element={<RenderWithPageFooter component={NotFoundPage} />}
                    />
                </Routes>
            </div>
        </Router>
    );
}

function RenderWithPageFooter({ component: Component, ...rest }) {
    const location = useLocation();
    const isAppRoute = location.pathname.startsWith('/app');

    if (isAppRoute) {
        return <Component {...rest} />;
    } else {
        return (
            <>
                <Navbar />
                <Component {...rest} />
                <Footer />
            </>
        );
    }
}

function RenderWithoutPageFooter({ component: Component, ...rest }) {
    return (
        <>
            <Navbar />
            <Component {...rest} />
        </>
    );
}

export default App;
