import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getPastAppointmentsByUserId } from '../services/AppointmentServices';
import { useUserId, useUserRole } from '../utils/Cookies';
import { getClientName } from '../services/UserServices';
import '../assets/styles/Record.css';

const RecordDentist = () => {
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [clients, setClients] = useState({});
    const [searchText, setSearchText] = useState('');
    const userId = useUserId();
    const role = useUserRole();

    useEffect(() => {
        const fetchAppointmentsAndClients = async () => {
            try {
                const fetchedAppointments = await getPastAppointmentsByUserId();
                const clientIds = new Set();
                const mappedAppointments = fetchedAppointments
                    .filter(appointment => appointment.status !== 'Cancelada')
                    .map(appointment => {
                        clientIds.add(appointment.participants[0]);
                        return {
                            _id: appointment._id,
                            fecha: appointment.date.day,
                            startTime: appointment.date.start_time,
                            endTime: appointment.date.end_time,
                            tratamiento: appointment.treatment,
                            cliente: appointment.participants[0],
                            status: appointment.status,
                            anamnesis: appointment.anamnesis || null,
                            valoracion: appointment.valoracion || null,
                        };
                    });
                setAppointments(mappedAppointments);
                setFilteredAppointments(mappedAppointments);
    
                const clientsMap = {};
                for (const id of clientIds) {
                    try {
                        const clientName = await getClientName(id);
                        if (clientName) {
                            clientsMap[id] = clientName;
                        }
                    } catch (error) {
                        console.error(`Error fetching user ${id} information:`, error);
                    }
                }
                setClients(clientsMap);
            } catch (error) {
                console.error('Error fetching appointments or clients:', error);
            }
        };
        fetchAppointmentsAndClients();
    }, []);

    const navigateToAnamnesis = (appointmentId) => {
        navigate(`/app/${role}/anamnesis/${appointmentId}`);
    };

    const navigateToValoracion = (appointmentId, hasValoracion) => {
        if (hasValoracion) {
            navigate(`/app/${role}/editar-valoracion/${appointmentId}`);
        } else {
            navigate(`/app/${role}/crear-valoracion/${appointmentId}`);
        }
    };

    const navigateToPresupuesto = (appointmentId) => {
        navigate(`/app/${role}/presupuesto/${appointmentId}`);
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);
        const filteredData = appointments.filter(item => 
            item.fecha.toLowerCase().includes(value) ||
            item.startTime.toLowerCase().includes(value) ||
            item.tratamiento.toLowerCase().includes(value) ||
            (clients[item.cliente]?.toLowerCase().includes(value) || item.cliente.toLowerCase().includes(value))
        );
        setFilteredAppointments(filteredData);
    };

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            width: 100,
            sorter: (a, b) => new Date(a.fecha) - new Date(b.fecha),
            render: (text) => new Date(text).toLocaleDateString('es-ES')
        },
        {
            title: 'Hora',
            dataIndex: 'startTime',
            width: 100,
            render: (text, record) => {
                const startTime = new Date(record.startTime).toLocaleTimeString('es-ES', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                const endTime = new Date(record.endTime).toLocaleTimeString('es-ES', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                return `${startTime} - ${endTime}`;
            },
        },
        {
            title: 'Tratamiento',
            dataIndex: 'tratamiento',
            width: 110,
        },
        {
            title: 'Cliente',
            dataIndex: 'cliente',
            width: 150,
            render: (text) => <span>{clients[text] || text}</span>,
        },
        {
            title: 'Anamnesis',
            dataIndex: '_id',
            width: 100,
            render: (text, record) => (
                record.anamnesis ? (
                    <Button onClick={() => navigateToAnamnesis(record._id)}>
                        Ver
                    </Button>
                ) : null
            ),
        },
        {
            title: 'Valoración',
            dataIndex: '_id',
            width: 100,
            render: (text, record) => (
                <Button onClick={() => navigateToValoracion(record._id, record.valoracion)}>
                    {record.valoracion ? 'Editar' : 'Crear'}
                </Button>
            ),
        },
        {
            title: 'Presupuestos',
            dataIndex: '_id',
            width: 100,
            render: (text, record) => (
                <Button onClick={() => navigateToPresupuesto(record._id)}>
                    Ver
                </Button>
            ),
        },
    ];

    const navigateToHome = () => {
        navigate(`/app`);
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={18} sm={22} xs={23} className="recordCard">
                    <Row>
                        <h1 className="home-title">Este es tu historial clínico</h1>
                    </Row>
                    <Input
                        placeholder="Buscar..."
                        value={searchText}
                        onChange={handleSearch}
                        style={{ marginBottom: 20 }}
                    />
                    <Table
                        columns={columns}
                        dataSource={filteredAppointments}
                        className="tabla font-small"
                        showSorterTooltip={{
                            title: 'Haga clic para ordenar',
                        }}
                        pagination={false}
                        scroll={{
                            y: 240,
                        }}
                        rowKey="_id"
                    />
                    <Col span={24} className="inputCol">
                        <Row justify={'space-between'}>
                            <Col>
                                <Button className="volverButton" onClick={navigateToHome}>
                                    VOLVER
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default RecordDentist;
