import React, { useEffect, useState } from 'react';
import { Col, Row, message, Popconfirm, Table, Button } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { cancelAppointmentbyAdmin } from '../services/AppointmentServices';
import { getAppointmentsByDayAndSpecialist, getAppointmentsByDay } from '../services/AppointmentServices';
import '../assets/styles/DateAppointments.css';
import { useUserId, useUserRole } from '../utils/Cookies';
import { getUser } from '../services/UserServices';
import { DeleteOutlined } from '@ant-design/icons';

const DateAppointments = () => {
    const { dia } = useParams();
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const especialistaId = useUserId();
    const userRole = useUserRole();

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                let fetchedAppointments;

                if (userRole === 'dentista') {
                    fetchedAppointments = await getAppointmentsByDayAndSpecialist(especialistaId, dia);
                } else if (userRole === 'admin' || userRole === 'secretaria') {
                    fetchedAppointments = await getAppointmentsByDay(dia);
                }

                const appointmentsWithUserDetails = await Promise.all(fetchedAppointments.map(async (appointment) => {
                    const user = await getUser(appointment.participants[0]);
                    let specialist = {};
                    if (userRole === 'admin'|| userRole === 'secretaria') {
                        specialist = await getUser(appointment.participants[1]);
                    }
                    return { ...appointment, cliente: user, especialista: specialist };
                }));

                setAppointments(appointmentsWithUserDetails);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching appointments:', error);
                setLoading(false);
            }
        };

        fetchAppointments();
    }, [especialistaId, dia, userRole]);

    const handleCancel = async (appointmentId) => {
        try {
            await cancelAppointmentbyAdmin(appointmentId);
            setAppointments((prevAppointments) =>
                prevAppointments.map((appointment) =>
                    appointment._id === appointmentId
                        ? { ...appointment, status: 'Cancelada' }
                        : appointment
                )
            );
            message.success('Cita cancelada correctamente');
        } catch (error) {
            console.error('Error canceling appointment:', error);
            message.error('No se pudo cancelar la cita');
        }
    };

    const columns = [
        {
            title: 'Hora',
            dataIndex: ['date', 'start_time'],
            key: 'start_time',
            render: (start_time) => moment(start_time).tz(moment.tz.guess()).format('HH:mm A')
        },
        {
            title: 'Paciente',
            dataIndex: ['cliente', 'username'],
            key: 'clienteId',
        },
        {
            title: 'Tratamiento',
            dataIndex: 'treatment',
            key: 'tratamiento',
        },
    ];

    if (userRole === 'admin' || userRole === 'secretaria') {
        columns.push(
            {
                title: 'Especialista',
                dataIndex: ['especialista', 'username'],
                key: 'especialistaId',
            },
            {
                title: 'Estado',
                dataIndex: 'status',
                key: 'estado',
            },
            {
                title: 'Acciones',
                key: 'acciones',
                render: (text, record) => (
                    record.status === 'Confirmada' ? (
                        <Popconfirm
                            title="¿Está seguro de cancelar este turno?"
                            onConfirm={() => handleCancel(record._id)}
                            okText="Sí"
                            cancelText="No"
                        >
                            <Button type="link" danger icon={<DeleteOutlined />} size="small" />
                        </Popconfirm>
                    ) : null
                ),
            }
        );
    }

    return (
        <div className="bckg">
            <Row justify="center">
                <Col xl={18} lg={20} sm={22} xs={22} className="DateAppointmentsCard">
                    <Row>
                        <h1 className="home-title paddleft">Estas son las citas del día: {moment(dia).format('YYYY-MM-DD')}</h1>
                    </Row>
                    <Table
                        dataSource={appointments}
                        columns={columns}
                        loading={loading}
                        rowKey="_id"
                        pagination={false}
                        scroll={{ y: 300 }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default DateAppointments;
