import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getValoracionByAppointmentId } from '../services/AppointmentServices';
import { List, Card, Image, message, Row, Col } from 'antd';

const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

const ValoracionView = () => {
    const { id: appointmentId } = useParams();
    const [valoracion, setValoracion] = useState(null);

    useEffect(() => {
        const fetchValoracion = async () => {
            try {
                const data = await getValoracionByAppointmentId(appointmentId);
                if (data && data.valoracion) {
                    setValoracion(data.valoracion);
                } else {
                    console.error('Valoracion no encontrada en la respuesta');
                    message.error('Valoracion no encontrada en la respuesta');
                }
            } catch (error) {
                console.error('Error al obtener la valoración:', error);
                message.error('Error al obtener la valoración');
            }
        };

        fetchValoracion();
    }, [appointmentId]);

    if (valoracion === null) {
        return <div>Cargando...</div>;
    }

    if (typeof valoracion !== 'object' || !valoracion.observaciones) {
        return <div>Datos no disponibles</div>;
    }

    return (
        <div className='bckg'>
            <Row justify="center">
                <Col lg={8} sm={12} xs={16} className="valoracionCard">
                    <h2>Valoración</h2>
                    <p><strong>Observaciones:</strong> {valoracion.observaciones}</p>
                    <h3>Imágenes</h3>
                    {valoracion.imagenes.length > 0 ? (
                        <List
                            grid={{ gutter: 16, column: 4 }}
                            dataSource={valoracion.imagenes}
                            renderItem={image => (
                                <List.Item>
                                    <Card>
                                        <Image src={`${REACT_APP_BFF_URL}${image}`} alt="imagen de valoración" />
                                    </Card>
                                </List.Item>
                            )}
                        />
                    ) : (
                        <p>No hay imágenes disponibles</p>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default ValoracionView;
