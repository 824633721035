import React, { useState } from 'react';
import { Button, Col, Input, message, Modal, Row } from 'antd';
import { recoverPassword } from '../services/UserServices';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/Agendar.css';

const NieForEmail = () => {
    const [NIE, setNIE] = useState('');
    const navigate = useNavigate();
    
    const handleBack = () => {
        navigate('/app');
    }
    
    const handleCheckUser = async () => {
        if (!NIE.trim()) {
            message.warning('Por favor, ingresa un NIE.');
            return;
        }
        try {
            const response = await recoverPassword(NIE);
            if (response && response.userId) {
                Modal.success({
                    title: 'Correo de Recuperación Enviado',
                    content: 'Hemos enviado un correo de recuperación a su dirección registrada.',
                    onOk() {
                        navigate(`/login`);
                    },
                });
            } else {
                message.error('Lo sentimos, no encontramos ningun usuario');
            }
        } catch (error) {
            message.error('Lo sentimos, no encontramos ningun usuario.');
        }
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={8} sm={12} xs={16} className="verificarCard">
                    <Row>
                        <h1 className="home-title">Recupere su contraseña</h1>
                        <p className="under-title">Ingrese su NIE y verificaremos sus datos.</p>
                    </Row>
                    <Row className="inputsRow">
                        <Col span={24} className="inputCol input-group">
                            <Input
                                placeholder="Ingresa el NIE"
                                value={NIE}
                                onChange={(e) => setNIE(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Button className="greenButton padright" onClick={handleBack}>
                            Volver
                        </Button>
                        <Button className='agendarButton' onClick={handleCheckUser}>
                            Enviar
                        </Button>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default NieForEmail;