import '../assets/styles/Login.css';
import { useState } from 'react';
import { Button, Card, Col, Form, Input, message, Row, Select } from 'antd';
import { LockOutlined, MailOutlined, UserOutlined, IdcardOutlined, PhoneOutlined, CloseOutlined } from '@ant-design/icons';
import { registerUser } from "../services/UserServices";
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

function Signindentists() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isDocTypeSelected, setIsDocTypeSelected] = useState(false);

    const handleFormSubmit = async () => {
        try {
            const values = await form.validateFields();
            await registerUser(values.NIE, values.Usuario, values.Correo, values.Contrasenia, "dentista", values.PhoneNumber);
            message.success('Usuario creado correctamente');
            navigate('/app/admin/dentistas');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                message.error('El usuario ya existe. Por favor, elija otro nombre de usuario.');
            } else {
                message.error('Error al crear usuario. Por favor, inténtelo nuevamente.');
            }
        }
    };

    const handleDocTypeChange = (value) => {
        if (value) {
            setIsDocTypeSelected(true);
        } else {
            setIsDocTypeSelected(false);
            form.setFieldsValue({ NIE: '' });
        }
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <Form form={form} name="control-hooks">
            <div className="loginalign">
                <Row className="maprow3">
                    <Col span={20} className="card-container">
                        <Card bordered={true} style={{ position: 'relative' }}>
                            <CloseOutlined
                                onClick={handleBackClick}
                                style={{
                                    position: 'absolute',
                                    top: 10,
                                    left: 10,
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                }}
                            />
                            <span className="title">
                                Registrar Dentista
                            </span>
                            <Form.Item
                                className="formitem0"
                                rules={[{ required: true, message: 'Seleccione el tipo de documento y ingrese su número de documento' }]}
                            >
                                <Input.Group compact style={{ display: 'flex' }}>
                                    <Form.Item
                                        name="tipoDocumento"
                                        noStyle
                                        rules={[{ required: true, message: 'Seleccione el tipo de documento' }]}
                                    >
                                        <Select placeholder="Tipo" className='custom-select' onChange={handleDocTypeChange}>
                                            <Option value="DNI">DNI</Option>
                                            <Option value="NIE">NIE</Option>
                                            <Option value="Pasaporte">Pasaporte</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="NIE"
                                        noStyle
                                        rules={[{ required: isDocTypeSelected, message: 'Ingrese su número de documento' }]}
                                    >
                                        <Input
                                            style={{ width: '70%' }}
                                            placeholder="Número de documento"
                                            prefix={<IdcardOutlined className="site-form-item-icon" />}
                                            disabled={!isDocTypeSelected}
                                        />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                            <Form.Item
                                className="formitem1"
                                name="Usuario"
                                rules={[{ required: true, message: 'Ingrese su usuario' }]}
                            >
                                <Input
                                    className='aa'
                                    placeholder="Nombre de Usuario"
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item
                                className="formitem1"
                                name="PhoneNumber"
                                rules={[{ required: true, message: 'Ingrese su número de teléfono' }]}
                            >
                                <Input
                                    className='aa'
                                    placeholder="Número de Teléfono"
                                    prefix={<PhoneOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item
                                className="formitem1"
                                name="Correo"
                                rules={[
                                    { required: true, message: 'Ingrese su correo electrónico' },
                                    { type: 'email', message: 'Ingrese un correo válido' },
                                ]}
                            >
                                <Input
                                    className='aa'
                                    placeholder="Correo Electrónico"
                                    prefix={<MailOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item
                                className="formitem1"
                                name="Contrasenia"
                                rules={[{ required: true, message: 'Ingrese su contraseña' }]}
                            >
                                <Input.Password
                                    className='aa'
                                    placeholder="Contraseña"
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item
                                className="formitem1"
                                name="ReContrasenia"
                                dependencies={['Contrasenia']}
                                rules={[
                                    { required: true, message: 'Repita su contraseña' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('Contrasenia') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Las contraseñas no coinciden'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    className='aa'
                                    placeholder="Repetir Contraseña"
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Button
                                type="primary"
                                shape="round"
                                className="buttonlogin"
                                htmlType="submit"
                                onClick={handleFormSubmit}
                            >
                                Registrar
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Form>
    );
}

export default Signindentists;
