import React from 'react';
import {Col, Row} from 'antd';
import '../assets/styles/Footer.css';
import {FacebookOutlined, InstagramOutlined, TwitterOutlined} from '@ant-design/icons';

const Footer = () => {
    return (
        <Row className="footer">
            <Col className="footerCol">
                <p className="footerP">Sobre Nosotros</p>
                <span className="footerSpan">Somos mucho más que una clínica dental. Queremos ser parte de tu historia. Y queremos que tú seas parte de nuestra historia también…</span>
            </Col>
            <Col className="footerCol">
                <p className="footerP">Horarios</p>
                <p className="footerSpan">Lunes a viernes: 10h a 14h y 16h a 20h</p>
                {/* <p className="footerSpan">Sábados: 8.00-13.00</p> */}
            </Col>
            <Col className="footerCol">
                <p className="footerP">Contáctanos</p>
                <span className="footerSpan">¡Cuida tu sonrisa con nosotros! Contáctanos para citas y consulta. Estamos aquí para ayudarte a mantener tu salud dental en óptimas condiciones.</span>
            </Col>
            <Col span={24} className="bottomrow">
                <Row className="center">
                    <Col><a href="https://www.instagram.com/smileup.es/?hl=es-la" target="_blank"
                            rel="noreferrer noopener" className="circle"><InstagramOutlined
                        className="iconsize"/></a></Col>
                    <Col><a href="https://www.facebook.com/share/GAQvZ1xzjt9X6AZN/?mibextid=LQQJ4d" target="_blank"
                            rel="noreferrer noopener" className="circle"><FacebookOutlined
                        className="iconsize"/></a></Col>
                </Row>
            </Col>
        </Row>

    );
};

export default Footer;
