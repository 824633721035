import React, { useState } from 'react';
import '../assets/styles/Login.css';
import { Button, Card, Col, Form, Input, message, Modal, Row } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import { loginWithoutPassword, recoverPassword } from "../services/UserServices";
import { useNavigate } from 'react-router-dom';

function LoginDoc() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [NIE, setNIE] = useState('');

    const handleFormSubmit = async () => {
        try {
            const values = await form.validateFields();
            const loginSuccessful = await loginWithoutPassword(values.NIE);

            if (loginSuccessful) {
                message.success('Inicio de sesión exitoso.');
                await handleCheckUser(values.NIE);
            } else {
                message.error('Credenciales incorrectas. Intente nuevamente.');
            }
        } catch (error) {
            if (error.message === 'Please use your password to log in') {
                message.error('Utilice su contraseña para ingresar.');
                navigate('/login');
            } else {
                console.error('Form validation error:', error);
                message.error('Error en el inicio de sesión.');
            }
        }
    };

    const handleCheckUser = async (NIE) => {
        if (!NIE.trim()) {
            message.warning('Por favor, ingresa un NIE.');
            return;
        }
        try {
            const response = await recoverPassword(NIE);
            if (response && response.userId) {
                Modal.success({
                    title: 'Establezca su contraseña',
                    content: 'Hemos enviado un correo a su dirección registrada.',
                    onOk() {
                        navigate('/login');
                    },
                });
            } else {
                message.error('Lo sentimos, no encontramos ningun usuario');
            }
        } catch (error) {
            message.error('Lo sentimos, no encontramos ningun usuario.');
        }
    };

    return (
        <Form
            form={form}
            name="control-hooks"
            onFinish={handleFormSubmit}
        >
            <div className="loginalign">
                <Row className="maprow3">
                    <Col span={20} className="card-container">
                        <Card bordered={true}>
                            <span className="title">
                                Iniciar Sesión
                            </span>
                            <Form.Item className="formitem1" name="NIE"
                                rules={[{ required: true, message: 'Ingrese su documento' }]}>
                                <Input
                                    placeholder="Documento"
                                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                                    onChange={(e) => setNIE(e.target.value)}
                                />
                            </Form.Item>
                            <Button type="primary" shape="round" className="buttonlogin" htmlType="submit">
                                Iniciar
                            </Button>
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <div><span>¿No tienes Cuenta?</span></div>
                                <div><a className="noaccount" href="/signin">Regístrate</a></div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Form>
    );
}

export default LoginDoc;
