import '../assets/styles/Login.css';
import { useState } from 'react';
import { Button, Card, Col, Form, Input, message, Row, Select } from 'antd';
import { LockOutlined, MailOutlined, UserOutlined, IdcardOutlined, PhoneOutlined } from '@ant-design/icons';
import { registerUser } from "../services/UserServices";
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

function Signin() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [docType, setDocType] = useState(null);

    const handleFormSubmit = async () => {
        try {
            const values = await form.validateFields();
            await registerUser(values.NIE, values.Usuario, values.Correo, values.Contrasenia, "cliente", values.PhoneNumber);
            message.success('Usuario creado correctamente');
            navigate('/login');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                message.error('El usuario ya existe. Por favor, elija otro nombre de usuario.');
            } else {
                message.error('Error al crear usuario. Por favor, inténtelo nuevamente.');
            }
        }
    };

    const handleDocTypeChange = (value) => {
        setDocType(value);
        if (!value) {
            form.setFieldsValue({ NIE: '' });
        }
    };

    const getDocValidationRules = () => {
        if (docType === 'DNI') {
            return [
                { required: true, message: 'Ingrese su número de documento' },
                { pattern: /^[0-9]{8}[A-Z]$/, message: 'Formato de DNI inválido' }
            ];
        }
        if (docType === 'NIE') {
            return [
                { required: true, message: 'Ingrese su número de documento' },
                { pattern: /^[XYZ][0-9]{7}[A-Z]$/, message: 'Formato de NIE inválido' }
            ];
        }
        if (docType === 'Pasaporte') {
            return [
                { required: true, message: 'Ingrese su número de documento' },
                { min: 4, message: 'El pasaporte debe tener más de 4 caracteres' }
            ];
        }
        return [{ required: true, message: 'Ingrese su número de documento' }];
    };

    return (
        <Form form={form} name="control-hooks">
            <div className="loginalign">
                <Row className="maprow3">
                    <Col span={20} className="card-container">
                        <Card bordered={true}>
                            <span className="title">
                                Registrarse
                            </span>
                            <Form.Item
                                className="formitem0"
                                rules={[{ required: true, message: 'Seleccione el tipo de documento y ingrese su número de documento' }]}
                            >
                                <Input.Group compact style={{ display: 'flex' }}>
                                    <Form.Item
                                        name="tipoDocumento"
                                        noStyle
                                        rules={[{ required: true, message: 'Seleccione el tipo de documento' }]}
                                    >
                                        <Select placeholder="Tipo" className='custom-select' onChange={handleDocTypeChange}>
                                            <Option value="DNI">DNI</Option>
                                            <Option value="NIE">NIE</Option>
                                            <Option value="Pasaporte">Pasaporte</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="NIE"
                                        noStyle
                                        rules={getDocValidationRules()}
                                    >
                                        <Input
                                            style={{ width: '70%' }}
                                            placeholder="Número de documento"
                                            prefix={<IdcardOutlined className="site-form-item-icon" />}
                                            disabled={!docType}
                                        />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                            <Form.Item
                                className="formitem1"
                                name="Usuario"
                                rules={[
                                    { required: true, message: 'Ingrese su usuario' },
                                    { min: 5, message: 'El nombre de usuario debe tener más de 4 caracteres' },
                                ]}
                            >
                                <Input
                                    className='aa'
                                    placeholder="Nombre de Usuario"
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item
                                className="formitem1"
                                name="PhoneNumber"
                                rules={[
                                    { required: true, message: 'Ingrese su número de teléfono' },
                                    { pattern: /^\d{3} \d{3} \d{3}$/, message: 'Formato: 111 111 111' },
                                ]}
                            >
                                <Input
                                    className='aa'
                                    placeholder="Número de Teléfono"
                                    prefix={<PhoneOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item
                                className="formitem1"
                                name="Correo"
                                rules={[
                                    { required: true, message: 'Ingrese su correo electrónico' },
                                    { type: 'email', message: 'Ingrese un correo válido' },
                                ]}
                            >
                                <Input
                                    className='aa'
                                    placeholder="Correo Electrónico"
                                    prefix={<MailOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item
                                className="formitem1"
                                name="Contrasenia"
                                rules={[{ required: true, message: 'Ingrese su contraseña' }]}
                            >
                                <Input.Password
                                    className='aa'
                                    placeholder="Contraseña"
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item
                                className="formitem1"
                                name="ReContrasenia"
                                dependencies={['Contrasenia']}
                                rules={[
                                    { required: true, message: 'Repita su contraseña' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('Contrasenia') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Las contraseñas no coinciden'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    className='aa'
                                    placeholder="Repetir Contraseña"
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Button
                                type="primary"
                                shape="round"
                                className="buttonlogin"
                                htmlType="submit"
                                onClick={handleFormSubmit}
                            >
                                Registrar
                            </Button>
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <div><span>Ya tienes Cuenta?</span></div>
                                <div><a className="noaccount" href="/login">Iniciar sesión</a></div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Form>
    );
}

export default Signin;
