import NotFoundComp from '../components/NotFoundComp';

const NotFoundPage = () => {

    return (
        <div className="loginalign bckg">
            <NotFoundComp/>
        </div>
    );
};

export default NotFoundPage;
