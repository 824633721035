import Cookies from "universal-cookie";

const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const registerUser = async (NIE, username, email, password, role, phoneNumber) => {
    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/register`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                NIE: NIE,
                username: username,
                email: email,
                password: password,
                role: role,
                phoneNumber: phoneNumber
            })
        });

        if (!response.ok) {
            throw new Error('Failed to register user');
        }
        return await response.json();

    } catch (err) {
        console.error('Error al registrar usuario:', err);
        throw err;
    }
};

export const loginUser = async (NIE, password) => {
    const cookies = new Cookies(null, {path: "/"});

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                NIE: NIE,
                password: password,
            })
        });

        if (!response.ok) {
            throw new Error('Failed to login user');
        }

        const data = await response.json();

        const expirationTime = new Date();
        expirationTime.setDate(expirationTime.getDate() + 30);

        cookies.set("access-token", data.accessToken, {
            expires: expirationTime,
            path: "/"
        });
        return true;

    } catch (err) {
        return false;
    }
};

export const getUser = async (userId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/users/${userId}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch information for user ${userId}`);
        }

        const data = await response.json();

        return data;

    } catch (err) {
        console.error(`Error fetching user ${userId} information:`, err);
        return null;
    }
};
export const updateUser = async (userId, newUsername, email, role) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/users/me`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include",
            body: JSON.stringify({
                username: newUsername,
                email: email,
                role: role
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();

    } catch (err) {
        console.error(`Error updating user ${userId}:`, err);
        return null;
    }
};

export const getClientes = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/users/clients`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch clientes');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error fetching clientes information:', err);
        return null;
    }
};

export const getSecretarias = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/users/secretarias/all`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch secretarias');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error fetching secretarias information:', err);
        return null;
    }
};

export const getDentistas = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/users/dentists`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch dentistas');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error fetching dentistas information:', err);
        return null;
    }
};

export const getDentistName = async (dentistId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/users/dentist/${dentistId}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch name for dentist ${dentistId}`);
        }

        const data = await response.json();
        return data.name;

    } catch (err) {
        console.error(`Error fetching dentist ${dentistId} name:`, err);
        return null;
    }
};

export const getClientName = async (clientId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/users/client/${clientId}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch name for dentist ${clientId}`);
        }

        const data = await response.json();

        return data.name;

    } catch (err) {
        console.error(`Error fetching dentist ${clientId} name:`, err);
        return null;
    }
};
export const updateUserByAdmin = async (userId, username, email, phoneNumber, NIE, workingHours, treatments, vacations) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    // Format the working hours inside the service function
    const formatTime = (timeString) => {
        if (!timeString) return ''; // Handle empty values
        const [datePart, timePart] = timeString.split('T');
        return `${datePart}T${timePart.slice(0, 5)}Z`; // Ensure timePart is correctly sliced
    };

    const formattedWorkingHours = workingHours.map(hours => {
        const startTime = formatTime(hours.startTime);
        const endTime = formatTime(hours.endTime);

        // Validate if the date is valid
        if (isNaN(new Date(startTime)) || isNaN(new Date(endTime))) {
            console.error('Invalid time value:', { startTime, endTime });
            throw new Error('Invalid time value');
        }

        return {
            ...hours,
            startTime,
            endTime
        };
    });

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/admin/update-user/${userId}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include",
            body: JSON.stringify({
                username,
                email,
                phoneNumber,
                NIE,
                workingHours: formattedWorkingHours,
                treatments,
                vacations
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        return {
            status: response.status,
            ...responseData
        };

    } catch (err) {
        console.error(`Error updating user ${userId}:`, err);
        return null;
    }
};

export const deleteUser = async (userId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/admin/delete-user/${userId}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error(`Failed to delete user ${userId}`);
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error(`Error deleting user ${userId}:`, err);
        return null;
    }
};

export const checkUserExists = async (NIE) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");
    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/users/check`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({NIE})
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || `Failed to check if user ${NIE} exists. Status: ${response.status}`);
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error(`Error checking if user ${NIE} exists:`, err);
        return null;
    }
};

export const registerUserForAppointment = async (nombre, NIE, email, telefono) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/nouser/register`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include",
            body: JSON.stringify({
                nombre,
                NIE,
                email,
                telefono
            })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to register user for appointment');
        }

        const data = await response.json();
        return data.userId;

    } catch (err) {
        console.error('Error al registrar usuario para la cita:', err);
        throw err;
    }
};

export const loginWithoutPassword = async (NIE) => {
    const cookies = new Cookies();

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/logindoc`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ NIE })
        });

        if (!response.ok) {
            const errorData = await response.json();
            if (errorData.error === 'Please use your password to log in') {
                throw new Error('Please use your password to log in');
            } else {
                throw new Error(errorData.error || 'Failed to login without password');
            }
        }

        const data = await response.json();

        if (data.logged) {
            // const expirationTime = new Date();
            // expirationTime.setDate(expirationTime.getDate() + 30);

            // cookies.set("access-token", data.accessToken, {
            //     expires: expirationTime,
            //     path: "/"
            // });
            return true;
        } else {
            return false;
        }

    } catch (err) {
        console.error('Error logging in without password:', err);
        throw err;
    }
};
export const recoverPassword = async (NIE) => {
    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/recover`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ NIE })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to send recovery email');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al enviar el correo de recuperación:', err);
        throw err;
    }
};

export const updatePassword = async (userId, token, newPassword) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/auth/update-password`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            credentials: "include",
            body: JSON.stringify({
                userId,
                token,
                newPassword
            })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to update password');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al actualizar la contraseña:', err);
        throw err;
    }
};
