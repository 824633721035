import '../assets/styles/Login.css';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import { LockOutlined, IdcardOutlined } from '@ant-design/icons';
import { loginUser } from "../services/UserServices";
import { useNavigate } from 'react-router-dom';

function Login() {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const handleFormSubmit = async () => {
        try {
            const values = await form.validateFields();
            const loginSuccessful = await loginUser(values.NIE, values.Contrasenia);

            if (loginSuccessful) {
                message.success('Inicio de sesión exitoso.');
                navigate('/app');
            } else {
                message.error('Credenciales incorrectas. Intente nuevamente.');
            }
        } catch (error) {
            console.error('Form validation error:', error);
            message.error('Error en el inicio de sesión.');
        }
    };

    return (
        <Form
            form={form}
            name="control-hooks"
        >
            <div className="loginalign">
                <Row className="maprow3">
                    <Col span={20} className="card-container">
                        <Card bordered={true}>
                            <span className="title">
                                Iniciar Sesión
                            </span>
                            <Form.Item className="formitem1" name="NIE"
                                rules={[{ required: true, message: 'Ingrese su documento' }]}>
                                <Input
                                    placeholder="Documento"
                                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item className="formitem2" name="Contrasenia"
                                rules={[{ required: true, message: 'Ingrese su contraseña' }]}>
                                <Input.Password
                                    placeholder="Contraseña"
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Button type="primary" shape="round" className="buttonlogin" htmlType="submit"
                                onClick={handleFormSubmit}>
                                Iniciar
                            </Button>
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <div><span className='noaccounttitle'>¿Olvidaste tu contraseña?</span></div>
                                <div className='nomargintop'><a className="noaccount" href="/recover">Recuperar</a></div>
                                <div><span className='noaccounttitle'>¿No tienes Cuenta?</span></div>
                                <div className='nomargintop'><a className="noaccount" href="/signin">Regístrate</a></div>
                                <div><span className='noaccounttitle'>Recibiste una cita presencial?</span></div>
                                <div className='nomargintop'><a className="noaccount" href="/logindoc">Ingresar con DNI/NIE</a></div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Form>
    );
}

export default Login;
