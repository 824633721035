import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Input, message, Popconfirm } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser, updateUserByAdmin, deleteUser } from '../services/UserServices';
import '../assets/styles/IndividualDentistManage.css';

const IndividuaSecreManage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [dentist, setDentist] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [originalDentist, setOriginalDentist] = useState(null);

    useEffect(() => {
        const fetchDentist = async () => {
            try {
                const fetchedDentist = await getUser(id);
                setDentist(fetchedDentist);
                setOriginalDentist(JSON.parse(JSON.stringify(fetchedDentist)));
            } catch (error) {
                console.error('Error fetching dentist:', error);
            }
        };

        fetchDentist();
    }, [id]);

    const navigateToHome = () => {
        navigate(`/app/admin/secretarios`);
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        const hasChanges = JSON.stringify(dentist) !== JSON.stringify(originalDentist);

        if (!hasChanges) {
            message.info('No se detectaron cambios.');
            setIsEditing(false);
            return;
        }

        if (!dentist.username || !dentist.phoneNumber || !dentist.email) {
            message.error('Todos los campos deben estar llenos.');
            return;
        }

        const dentistToSave = {
            ...dentist
        };

        try {
            const response = await updateUserByAdmin(
                id,
                dentistToSave.username,
                dentistToSave.email,
                dentistToSave.phoneNumber,
                dentistToSave.NIE || '',
                dentistToSave.workingHours || [],
                dentistToSave.treatments || [],
                dentistToSave.vacations || []
            );
            if (response.status === 200) {
                setOriginalDentist(JSON.parse(JSON.stringify(dentist)));
                setIsEditing(false);
                message.success('¡Detalles del dentista actualizados exitosamente!');
            } else {
                message.error('Error al actualizar los detalles del dentista.');
            }
        } catch (error) {
            console.error('Error updating user', id, ':', error);
            if (error.response) {
                const { status } = error.response;
                if (status === 400) {
                    message.error('Error de solicitud: datos inválidos.');
                } else if (status === 500) {
                    message.error('Error en el servidor: no se pudo completar la solicitud.');
                } else {
                    message.error('Error al actualizar los detalles del dentista.');
                }
            } else {
                message.error('Error al actualizar los detalles del dentista.');
            }
        }
    };

    const handleDeleteClick = async () => {
        try {
            const response = await deleteUser(id);
            message.success('Usuario eliminado exitosamente.');
            navigate('/app/admin/secretarios');
        } catch (error) {
            console.error('Error deleting user', id, ':', error);
            message.error('Error al eliminar el usuario.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDentist({
            ...dentist,
            [name]: value,
        });
    };

    if (!dentist) {
        return <div>Loading...</div>;
    }

    return (
        <div className="bckg">
            <Row justify="center">
                <Col xl={14} lg={18} sm={22} xs={22} className="dentistcard">
                    <Row>
                        <Col span={24}>
                            <h1 className="home-title">Modificar Dentista</h1>
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col span={12} className="detail-title">Rol:</Col>
                        <Col span={12}>
                            {dentist.role}
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col span={12} className="detail-title">Documento:</Col>
                        <Col span={12}>
                            {dentist.NIE}
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col span={12} className="detail-title">Usuario:</Col>
                        <Col span={12}>
                            {isEditing ? (
                                <Input
                                    name="username"
                                    value={dentist.username}
                                    onChange={handleChange}
                                />
                            ) : (
                                dentist.username
                            )}
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col span={12} className="detail-title">Teléfono:</Col>
                        <Col span={12}>
                            {isEditing ? (
                                <Input
                                    name="phoneNumber"
                                    value={dentist.phoneNumber}
                                    onChange={handleChange}
                                />
                            ) : (
                                dentist.phoneNumber
                            )}
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col span={12} className="detail-title">Email:</Col>
                        <Col span={12}>
                            {isEditing ? (
                                <Input
                                    name="email"
                                    value={dentist.email}
                                    onChange={handleChange}
                                />
                            ) : (
                                dentist.email
                            )}
                        </Col>
                    </Row>

                    <Row className='lastrow'>
                        <Col span={24}>
                            {isEditing ? (
                                <Row>
                                    <Button className="saveButton lastcollapse" onClick={handleSaveClick}>
                                        GUARDAR
                                    </Button>
                                    <Popconfirm
                                        title="¿Estás seguro de que quieres eliminar este usuario?"
                                        onConfirm={handleDeleteClick}
                                        okText="Sí"
                                        cancelText="No"
                                    >
                                        <Button type="danger" className='delete'>
                                            Eliminar Usuario
                                        </Button>
                                    </Popconfirm>
                                </Row>
                            ) : (
                                <Button className="editButton lastcollapse" onClick={handleEditClick}>
                                    EDITAR
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row className='lastrow'>
                        <Col span={24}>
                            <Button className="volverButton3" onClick={navigateToHome}>
                                VOLVER
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default IndividuaSecreManage;
