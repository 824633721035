import React, { useState, useEffect } from 'react';
import { Col, Row, Radio, Input, Button, DatePicker, Divider, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';  // Importa moment
import { updateAnamnesis, getAnamnesisByAppointmentId } from '../services/AppointmentServices';
import '../assets/styles/AnamnesisCreation.css';

const AnamnesisCreation = () => {
    const { id: appointmentId } = useParams();
    const [isAllergic, setIsAllergic] = useState(null);
    const [allergyDetail, setAllergyDetail] = useState(null);
    const [isMedicated, setIsMedicated] = useState(null);
    const [medicationDetail, setMedicationDetail] = useState(null);
    const [reason, setReason] = useState(null);
    const [gumsBleed, setGumsBleed] = useState(null);
    const [teethMobility, setTeethMobility] = useState(null);
    const [teethColorSatisfaction, setTeethColorSatisfaction] = useState(null);
    const [sensitivity, setSensitivity] = useState(null);
    const [surgery, setSurgery] = useState(null);
    const [alcohol, setAlcohol] = useState(null);
    const [smoker, setSmoker] = useState(null);
    const [lastDoctorVisit, setLastDoctorVisit] = useState(null);
    const [lastDentistVisit, setLastDentistVisit] = useState(null);
    const [diabetes, setDiabetes] = useState(null);
    const [hypertension, setHypertension] = useState(null);
    const [bruxism, setBruxism] = useState(null);
    const [pregnant, setPregnant] = useState(null);
    const [lactating, setLactating] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const anamnesisData = await getAnamnesisByAppointmentId(appointmentId);
                if (anamnesisData) {
                    setReason(anamnesisData.reason || '');
                    setGumsBleed(anamnesisData.gumsBleed || null);
                    setTeethMobility(anamnesisData.teethMobility || null);
                    setTeethColorSatisfaction(anamnesisData.teethColorSatisfaction || null);
                    setSensitivity(anamnesisData.sensitivity || null);
                    setAllergyDetail(anamnesisData.allergyDetail || '');
                    setMedicationDetail(anamnesisData.medicationDetail || '');
                    setSurgery(anamnesisData.surgery || null);
                    setAlcohol(anamnesisData.alcohol || null);
                    setSmoker(anamnesisData.smoker || null);
                    setLastDoctorVisit(anamnesisData.lastDoctorVisit ? moment(anamnesisData.lastDoctorVisit) : null);
                    setLastDentistVisit(anamnesisData.lastDentistVisit ? moment(anamnesisData.lastDentistVisit) : null);
                    setDiabetes(anamnesisData.diabetes || null);
                    setHypertension(anamnesisData.hypertension || null);
                    setBruxism(anamnesisData.bruxism || null);
                    setPregnant(anamnesisData.pregnant || null);
                    setLactating(anamnesisData.lactating || null);
                    setIsAllergic(anamnesisData.allergyDetail ? 1 : null);
                    setIsMedicated(anamnesisData.medicationDetail ? 1 : null);
                }
            } catch (error) {
                console.error('Error fetching anamnesis data:', error);
            }
        };

        fetchData();
    }, [appointmentId]);

    const handleAllergyChange = e => {
        const { value } = e.target;
        setIsAllergic(value);
        if (value === 2) {
            setAllergyDetail(null);
        }
    };

    const handleMedicationChange = e => {
        const { value } = e.target;
        setIsMedicated(value);
        if (value === 2) {
            setMedicationDetail(null);
        }
    };

    const handleFinish = async () => {
        const fieldsFilled = [
            gumsBleed,
            teethMobility,
            teethColorSatisfaction,
            sensitivity,
            allergyDetail,
            medicationDetail,
            surgery,
            alcohol,
            smoker,
            lastDoctorVisit,
            lastDentistVisit,
            diabetes,
            hypertension,
            bruxism,
            pregnant,
            lactating
        ].filter(field => field !== null && field !== '' && field !== undefined).length;

        if (reason === '') {
            message.error('El campo "Motivo" es obligatorio');
        } else if (fieldsFilled < 3) {
            message.error('Debe completar al menos el Motivo y 2 campos más');
        } else {
            try {
                const anamnesisData = {
                    reason,
                    gumsBleed,
                    teethMobility,
                    teethColorSatisfaction,
                    sensitivity,
                    allergyDetail,
                    medicationDetail,
                    surgery,
                    alcohol,
                    smoker,
                    lastDoctorVisit,
                    lastDentistVisit,
                    diabetes,
                    hypertension,
                    bruxism,
                    pregnant,
                    lactating
                };
                await updateAnamnesis(appointmentId, anamnesisData);
                message.success('Anamnesis editada con éxito');
                navigate(`/app/secretaria/citas`);
            } catch (error) {
                console.error('Error al actualizar la anamnesis:', error);
                message.error('Error al actualizar la anamnesis');
            }
        }
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={8} sm={12} xs={16} className="anamnesisCard">
                    <Row>
                        <h2>Anamnesis</h2>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <label>Motivo</label>
                            <Input.TextArea rows={3} value={reason} onChange={e => setReason(e.target.value)} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <label>Sus encías suelen sangrar?</label>
                            <Radio.Group value={gumsBleed} onChange={e => setGumsBleed(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                                <Radio value={3}>No sé</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={12}>
                            <label>Sus dientes tienen movilidad?</label>
                            <Radio.Group value={teethMobility} onChange={e => setTeethMobility(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                                <Radio value={3}>No sé</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <label>Está satisfecho con el color de tus dientes?</label>
                            <Radio.Group value={teethColorSatisfaction} onChange={e => setTeethColorSatisfaction(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={12}>
                            <label>Tiene sensibilidad?</label>
                            <Radio.Group value={sensitivity} onChange={e => setSensitivity(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <label>Alergia a alguna comida o medicamento o anestecia?</label>
                            <Radio.Group value={isAllergic} onChange={handleAllergyChange}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                            <Input
                                placeholder="Cual"
                                value={allergyDetail}
                                onChange={e => setAllergyDetail(e.target.value)}
                                disabled={isAllergic !== 1}
                            />
                        </Col>
                        <Col span={12}>
                            <label>Está usando alguna medicación?</label>
                            <Radio.Group value={isMedicated} onChange={handleMedicationChange}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                            <Input
                                placeholder="Cual"
                                value={medicationDetail}
                                onChange={e => setMedicationDetail(e.target.value)}
                                disabled={isMedicated !== 1}
                            />
                        </Col>
                    </Row>
                    <Divider style={{ margin: '20px 0', height: '3px', backgroundColor: '#000' }} />
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <label>Ya hizo alguna cirugía?</label>
                            <Radio.Group value={surgery} onChange={e => setSurgery(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={12}>
                            <label>Alcohol?</label>
                            <Radio.Group value={alcohol} onChange={e => setAlcohol(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <label>Fumante?</label>
                            <Radio.Group value={smoker} onChange={e => setSmoker(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Divider style={{ margin: '20px 0', height: '3px', backgroundColor: '#000' }} />
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <label>Ultima visita al médico?</label>
                            <DatePicker
                                placeholder="Cuando"
                                style={{ width: '100%' }}
                                value={lastDoctorVisit ? moment(lastDoctorVisit) : null}
                                onChange={(date, dateString) => setLastDoctorVisit(dateString)}
                            />
                        </Col>
                        <Col span={12}>
                            <label>Ultima visita al dentista?</label>
                            <DatePicker
                                placeholder="Cuando"
                                style={{ width: '100%' }}
                                value={lastDentistVisit ? moment(lastDentistVisit) : null}
                                onChange={(date, dateString) => setLastDentistVisit(dateString)}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <label>Diabetes?</label>
                            <Radio.Group value={diabetes} onChange={e => setDiabetes(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={12}>
                            <label>Hipertensión?</label>
                            <Radio.Group value={hypertension} onChange={e => setHypertension(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <label>Bruxismo?</label>
                            <Radio.Group value={bruxism} onChange={e => setBruxism(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                                <Radio value={3}>No sé</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Divider style={{ margin: '20px 0', height: '3px', backgroundColor: '#000' }} />
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <label>Embarazada?</label>
                            <Radio.Group value={pregnant} onChange={e => setPregnant(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={12}>
                            <label>En período de lactancia?</label>
                            <Radio.Group value={lactating} onChange={e => setLactating(e.target.value)}>
                                <Radio value={1}>Sí</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row justify="center" style={{ marginTop: '20px' }}>
                        <Button className='agendarButton' onClick={handleFinish}>Finalizar</Button>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AnamnesisCreation;
