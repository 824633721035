import React, {useEffect, useState} from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../assets/styles/Contact.css';
import {ClockCircleFilled, EnvironmentFilled, PhoneFilled} from '@ant-design/icons';
import {Button, Col, Input, message, Row} from 'antd';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import axios from 'axios';

const Contact = () => {
    const {TextArea} = Input;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        reason: '',
        message: ''
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async () => {
        try {
            if (!formData.name || !formData.email || !formData.phone || !formData.reason || !formData.message) {
                message.error('Por favor complete todos los campos.');
                return;
            }

            const headers = {
                'X-ElasticEmail-ApiKey': '9FFF0CB2B9B12F61D8C970C2BC2FD444C750380EC435EF0C758C2163B295704A726870D7DD04781FC28FEAD4F7E1C18F',
                'Content-Type': 'application/json',

            };

            const body = {
                "Recipients": [
                    {
                        "Email": "contact@smileup.com.es"
                    }
                ],
                "Content": {
                    "Body": [
                        {
                            "ContentType": "HTML",
                            "Content": `<p>Nombre: ${formData.name}</p><p>Correo: ${formData.email}</p><p>Teléfono: ${formData.phone}</p><p>Motivo: ${formData.reason}</p><p>Mensaje: ${formData.message}</p><p>Hola,</p><p>Este correo fue enviado para notificarle de que una persona espera su atención:</p><p>Muchísimas Gracias,<br>La dupla galáctica</p><p><a href='https://31563351.onedirect.in/messaging/dashboard' style='background-color: #007BFF; color: #fff; padding: 10px 20px; text-decoration: none; border-radius: 4px;'>Página de agente</a></p>`,
                            "Charset": "UTF-8"
                        }
                    ],
                    "From": "Bot <contact@smileup.com.es>",
                    "Subject": "Hello!"
                }
            };


            await axios.post('https://api.elasticemail.com/v4/emails', body, {headers});
            message.success('El correo se envió correctamente!');
        } catch (error) {
            console.error('Error sending email:', error);
            message.error('No se pudo enviar el correo. Por favor, inténtelo nuevamente más tarde.');
        }
    };

    useEffect(() => {
        if (!document.getElementById('map')._leaflet_id) {
            console.log("Initializing map...");
            const map = L.map('map').setView([38.395567, -0.4338606], 15);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
            }).addTo(map);

            const customIcon = L.icon({
                iconUrl: markerIcon,
                shadowUrl: markerShadow,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            });

            const marker = L.marker([38.39543, -0.4340000], {icon: customIcon}).addTo(map);
            marker.bindPopup("Nuestra Ubicación");

            console.log("Map initialized successfully.");
            console.log("Map element:", document.getElementById('map'));
        } else {
            console.log("Map container is already initialized.");
        }
    }, []);

    return (
        <Row className="maprow" id="Contactanos">
            <Col md={7} sm={14} xs={14} className="leftCol">
                <div id="map"></div>
            </Col>
            <Col md={7} sm={14} xs={14} className="rightCol">
                <Col span={18}>
                    <Col span={24}>
                        <span className="contactitle">Contáctanos</span>
                    </Col>
                    <Row className="inputsrow">
                        <Col span={11} className="col1">
                            <Input placeholder="Nombre" className="inputcontact" name="name" value={formData.name}
                                   onChange={handleChange} required/>
                        </Col>
                        <Col span={11} className="col1">
                            <Input placeholder="Correo" className="inputcontact" name="email" value={formData.email}
                                   onChange={handleChange} required/>
                        </Col>
                        <Col span={11}>
                            <Input placeholder="Teléfono" className="inputcontact" name="phone" value={formData.phone}
                                   onChange={handleChange} required/>
                        </Col>
                        <Col span={11}>
                            <Input placeholder="Motivo" className="inputcontact" name="reason" value={formData.reason}
                                   onChange={handleChange} required/>
                        </Col>
                    </Row>
                    <Col span={24}>
                        <div style={{maxHeight: '150px', overflowY: 'auto'}}>
                            <TextArea rows={4} maxLength={300} placeholder="Tu mensaje" name="message"
                                      value={formData.message} onChange={handleChange} required/>
                        </div>
                        <Button type="primary" className="contactbutton" onClick={handleSubmit}>Enviar</Button>
                    </Col>
                </Col>
            </Col>
            <Col className="maprow2" span={18}>
                <Row className="alignment2">
                    <Col className="height" md={7} xs={13}>
                        <Row className="contactcontent">
                            <Col span={4}>
                                <PhoneFilled/>
                            </Col>
                            <Col span={20}>
                                <span className="titlee">  +34 865 796 634 </span>
                                <p className="subtitle">bruna@smileup.com.es</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col className="height" md={7} xs={13}>
                        <Row className="contactcontent">
                            <Col span={4}>
                                <EnvironmentFilled/>
                            </Col>
                            <Col span={20}>
                                <span className="titlee"> Plaça del pou, 2 bajo izquierdo </span>
                                <p className="subtitle">Sant Joan d’Alacant</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col className="height" md={7} xs={13}>
                        <Row className="contactcontent">
                            <Col span={4}>
                                <ClockCircleFilled/>
                            </Col>
                            <Col span={20}>
                                <span className="titlee">  Lunes a Viernes: </span>
                                <p className="subtitle">10h a 14h y 16h a 20h</p>
                            </Col>
                        </Row>

                    </Col>
                </Row>

            </Col>
        </Row>
    );
};

export default Contact;
