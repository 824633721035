import React from 'react';
import {Button, Result, Row} from 'antd';
import '../assets/styles/NotFoundComp.css';
import {useAuthCookie} from '../utils/Cookies';
import {useNavigate} from 'react-router-dom';

const NotFoundComp = () => {
    const isAuthenticated = useAuthCookie();
    const navigate = useNavigate();

    const handleVolver = () => {
        if (isAuthenticated) {
            navigate('/app');
        } else {
            navigate('/');
        }
    };

    return (
        <Result
            status="404"
            title="Error 404"
            subTitle="¡ OOPS !.... Lo sentimos, la página a la que intentas acceder no existe."
            extra={
                <Row className="alignmiddle">
                    <Button type="primary" onClick={handleVolver} className="volverButton">Volver al inicio</Button>
                </Row>
            }
            className="page404"
        />
    );
};

export default NotFoundComp;
