import React, { useState } from 'react';
import { Button, Col, Input, message, Row } from 'antd';
import { checkUserExists } from '../services/UserServices';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/Agendar.css';

const AdminManage = () => {
    const navigate = useNavigate();

    const handleBack = async () => {
        navigate('/app')
    }

    const handleDentistas = async () => {
        navigate('/app/admin/dentistas')
    };

    const handleSecretarios = async () => {
        navigate('/app/admin/secretarios')
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={9} sm={12} xs={16} className="gestionarCard">
                    <Row>
                        <h1 className="home-title">Gestione sus dentistas y secretarios aqui</h1>
                    </Row>
                    <Row className='padtop2' justify="center">
                        <Col>
                        <Row justify="center">

                            <Button className='agendarButton padright' onClick={handleDentistas}>
                                Dentistas
                            </Button>
                            <Button className='agendarButton padleft' onClick={handleSecretarios}>
                                Secretarios
                            </Button>
                        </Row>
                        <Row justify="center padtop2">
                            <Button className="greenButton" onClick={handleBack}>
                                Volver
                            </Button>
                        </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AdminManage;
