import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getSecretarias } from '../services/UserServices';

const SecreAdminManage = () => {
    const navigate = useNavigate();
    const [secretaries, setSecretaries] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchSecretaries = async () => {
            try {
                const fetchedSecretaries = await getSecretarias();
                setSecretaries(Array.isArray(fetchedSecretaries) ? fetchedSecretaries : []);
            } catch (error) {
                console.error('Error fetching secretaries:', error);
                setSecretaries([]);
            }
        };
        fetchSecretaries();
    }, []);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        setCurrentPage(1);
    };

    const handleViewMore = (id) => {
        navigate(`./${id}`);
    };

    const filteredSecretaries = secretaries.filter(secretary =>
        (secretary.NIE || '').toLowerCase().includes(searchText.toLowerCase()) ||
        (secretary.username || '').toLowerCase().includes(searchText.toLowerCase()) ||
        (secretary.email || '').toLowerCase().includes(searchText.toLowerCase()) ||
        (secretary.phoneNumber || '').toLowerCase().includes(searchText.toLowerCase())
    );

    const columns = [
        {
            title: 'ID',
            dataIndex: 'NIE',
            key: 'NIE',
            width: 80,
        },
        {
            title: 'Usuario',
            dataIndex: 'username',
            key: 'username',
            width: 140,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 200,
        },
        {
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: 150,
        },
        {
            title: 'Acción',
            key: 'action',
            width: 140,
            render: (text, record) => (
                <Button onClick={() => handleViewMore(record._id)}>Ver más</Button>
            ),
        },
    ];

    const navigateToHome = () => {
        navigate(`/app/admin/gestionar`);
    };

    const navigateToCreate = () => {
        navigate(`/app/admin/secretarios/signin`);
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col xl={18} lg={18} sm={22} xs={22} className="clientsCard">
                    <Row>
                        <h1 className="home-title paddleft">Estos son tus secretarias</h1>
                    </Row>
                    <Row>
                        <Input
                            placeholder="Buscar"
                            className='search'
                            value={searchText}
                            onChange={handleSearch}
                            style={{ marginBottom: 20 }}
                        />
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={filteredSecretaries}
                        className="tabla font-small"
                        pagination={{
                            current: currentPage,
                            pageSize: 10,
                            onChange: page => setCurrentPage(page),
                        }}
                        scroll={{ y: 240 }}
                        rowKey="_id"
                    />

                    <Row>
                        <Col className="volver">
                            <Button className="volverButton2" onClick={navigateToHome}>
                                VOLVER
                            </Button>
                        </Col>
                        <Col className="volver">
                            <Button className="NuevaCitaButton" onClick={navigateToCreate}>
                                Agregar secretaria
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default SecreAdminManage;
