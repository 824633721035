import React from 'react';
import { Button, Col, Row } from 'antd';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title as ChartTitle, Tooltip, Legend } from 'chart.js';
import '../assets/styles/Contaduria.css';
import { useNavigate } from 'react-router-dom';
import {generateExcel} from '../utils/generarExcel'

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, ChartTitle, Tooltip, Legend);

const doughnutDataIncome = {
    labels: ['Filled', 'Empty'],
    datasets: [
        {
            data: [30, 70],
            backgroundColor: ['rgba(75,192,192,1)', 'rgba(230, 230, 230, 1)'],
            hoverBackgroundColor: ['rgba(75,192,192,0.4)', 'rgba(230, 230, 230, 0.4)']
        }
    ]
};

const doughnutDataOutcome = {
    labels: ['Filled', 'Empty'],
    datasets: [
        {
            data: [70, 30],
            backgroundColor: ['rgba(255,99,132,1)', 'rgba(230, 230, 230, 1)'],
            hoverBackgroundColor: ['rgba(255,99,132,0.4)', 'rgba(230, 230, 230, 0.4)']
        }
    ]
};

const doughnutOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        }
    }
};


const Contaduria = () => {
    const navigate = useNavigate();

    const handleIngresos = () => {
        navigate('./ingresos')
    }

    const handleEgresos = () => {
        navigate('./egresos')
    }

    const handleBack = () => {
        navigate('/app')
    }

    const handleResumen = () => {
        generateExcel();
    };

    return (
        <div className="home-client">
            <Row justify="center" className="cardsRow">
                <Col xl={16} sm={22} xs={23}>
                    <Row justify="center">
                        <Col span={22} className="carddd">
                            <Row justify={'space-between'}>
                                <Col span={24} className='alinear'>
                                    <a className='textttt'>Contaduría</a>
                                </Col>
                                <Col md={9} sm={24} xs={24} className='income'>
                                    <span className='textttt'>INGRESOS</span>
                                    <Doughnut data={doughnutDataIncome} options={doughnutOptions} className="doughnut-chart" />
                                    <Button type="primary" className='buttonlogin' onClick={handleIngresos}>Ver Ingresos</Button>
                                </Col>
                                <Col md={9} sm={24} xs={24} className='outcome'>
                                    <span className='textttt'>EGRESOS</span>
                                    <Doughnut data={doughnutDataOutcome} options={doughnutOptions} className="doughnut-chart" />
                                    <Button type="primary" className='buttonlogin' onClick={handleEgresos}>Ver Egresos</Button>
                                </Col>
                            </Row>
                            <Row justify="center" className="chart-row">
                                <Col span={24} className='alinear'>
                                    <Button type="primary" className='agendarButton' onClick={handleResumen}>Descargar resumen</Button>
                                </Col>
                                <Col span={24} className='alinear2'>
                                    <Button type="primary" className='volverButton paddleft' onClick={handleBack}>Volver</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Contaduria;
