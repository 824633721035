import React, {useEffect, useState} from 'react';
import {Button, Col, Dropdown, Menu, Row} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import logo from '../assets/images/logo.webp';
import '../assets/styles/Navbar.css';
import {useNavigate} from 'react-router-dom';
import {useAuthCookie} from '../utils/Cookies';

const Navbar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const isAuthenticated = useAuthCookie();

    const handleAppointmentClick = () => {
        if (isAuthenticated) {
            navigate('/app');
        } else {
            navigate('/login');
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setCollapsed(window.innerWidth <= 780);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const scrollToSectionMainPage = (sectionId) => {
        navigate('/');
        setTimeout(() => {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "start"});
            }
        }, 100);
    };

    const scrollToSectionContactanos = (sectionId) => {
        navigate('/contacto');
        setTimeout(() => {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "start"});
            }
        }, 100);
    };

    const scrollToSectionLogin = (sectionId) => {
        navigate('/login');
        setTimeout(() => {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "start"});
            }
        }, 100);
    };

    const menu = (
        <Menu>
            <Menu.Item key="inicio" onClick={() => scrollToSectionMainPage("inicio")}>Inicio</Menu.Item>
            <Menu.Item key="servicios" onClick={() => scrollToSectionMainPage("servicios")}>Servicios</Menu.Item>
            <Menu.Item key="contacto" onClick={() => scrollToSectionContactanos("Contactanos")}>Contáctanos</Menu.Item>
            <Menu.Item key="turno" onClick={() => handleAppointmentClick()}>Sacar Turno Online</Menu.Item>
        </Menu>
    );

    return (
        <Row className="Row" justify="space-between">
            <Col lg={4} className='logocol'>
                <img src={logo} alt="logo" className="logo" onClick={() => scrollToSectionMainPage("inicio")}/>
            </Col>
            <Col xl={20} className={`colNav ${collapsed && 'collapsed'}`}>
                <Row justify="end" className="alignvert ">
                    {collapsed ? (
                        <Col>
                            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                                <MenuOutlined className="menuicon"/>
                            </Dropdown>
                        </Col>
                    ) : (
                        <>
                            <Col>
                                <span className="NavItem"
                                      onClick={() => scrollToSectionMainPage("inicio")}>Inicio</span>
                            </Col>
                            <Col>
                                <span className="NavItem"
                                      onClick={() => scrollToSectionMainPage("servicios")}>Servicios</span>
                            </Col>
                            <Col>
                                <span className="NavItem"
                                      onClick={() => scrollToSectionContactanos("Contactanos")}>Contactános</span>
                            </Col>
                            <Col className="colbutton">
                                <Button type="primary" className="buttons NavItem" onClick={handleAppointmentClick}>
                                    Sacar Cita Online
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default Navbar;
