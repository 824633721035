import React, { useEffect, useState } from 'react';
import { Button, Col, message, Popconfirm, Row, Table, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { getAppointmentsByUserId, cancelAppointmentbyAdmin } from '../services/AppointmentServices';
import { useUserId } from '../utils/Cookies';
import { getUser } from '../services/UserServices';

const { Search } = Input;

const DentistManage = () => {
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [clients, setClients] = useState({});
    const userId = useUserId();

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const fetchedAppointments = await getAppointmentsByUserId(userId);
                const uniqueClients = [
                    ...new Set(fetchedAppointments.map(app => app.participants[0]))
                ];

                const clientsData = await Promise.all(
                    uniqueClients.map(id => getUser(id))
                );

                const clientsMap = clientsData.reduce((map, user) => {
                    map[user._id] = user.username;
                    return map;
                }, {});

                setClients(clientsMap);
                setAppointments(fetchedAppointments);
                setFilteredAppointments(fetchedAppointments);
            } catch (error) {
                console.error('Error fetching appointments or clients:', error);
            }
        };
        fetchAppointments();
    }, [userId]);

    const handleCancel = async (key) => {
        try {
            await cancelAppointmentbyAdmin(key);
            setAppointments(appointments.map(appointment =>
                appointment._id === key ? { ...appointment, status: 'Cancelada' } : appointment
            ));
            message.success('Cita cancelada correctamente');
        } catch (error) {
            console.error('Error cancelando la cita:', error);
            message.error('No se pudo cancelar la cita');
        }
    };

    const handleSearch = (value) => {
        const filteredData = appointments.filter(appointment =>
            clients[appointment.participants[0]].toLowerCase().includes(value.toLowerCase()) ||
            appointment.treatment.toLowerCase().includes(value.toLowerCase()) ||
            new Date(appointment.date.day).toLocaleDateString('es-ES').includes(value.toLowerCase()) ||
            appointment.status.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredAppointments(filteredData);
    };

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'date',
            width: 100,
            sorter: (a, b) => new Date(a.date.day) - new Date(b.date.day),
            render: (text, record) => new Date(record.date.day).toLocaleDateString('es-ES')
        },
        {
            title: 'Hora',
            dataIndex: 'start_time',
            width: 120,
            render: (text, record) => {
                const startTime = new Date(record.date.start_time).toLocaleTimeString('es-ES', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                const endTime = new Date(record.date.end_time).toLocaleTimeString('es-ES', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                return `${startTime} - ${endTime}`;
            },
        },
        {
            title: 'Tratamiento',
            dataIndex: 'treatment',
            width: 110,
        },
        {
            title: 'Cliente',
            dataIndex: 'participants',
            width: 150,
            render: (text, record) => {
                const clientId = record.participants[0];
                return clients[clientId];
            },
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            width: 120,
            filters: [
                { text: 'Confirmada', value: 'Confirmada' },
                { text: 'Cancelada', value: 'Cancelada' },
            ],
            onFilter: (value, record) => record.status.includes(value),
            render: text => text,
        },
        {
            title: '',
            dataIndex: '',
            width: 50,
            render: (_, record) => (
                record.status !== 'Cancelada' && (
                    <Popconfirm
                        title="¿Está seguro de cancelar este turno?"
                        onConfirm={() => handleCancel(record._id)}
                        okText="Sí"
                        cancelText="No"
                    >
                        <Button type="link" danger icon={<DeleteOutlined />} size="small" />
                    </Popconfirm>
                )
            ),
        },
    ];

    const navigateToHome = () => {
        navigate(`/app`);
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col xl={14} lg={18} sm={22} xs={22} className="recordCard">
                    <Row>
                        <h1 className="home-title">Estas son tus próximas citas</h1>
                    </Row>
                    <Row >
                        <Col span={24}>
                            <Search
                                placeholder="Buscar..."
                                onSearch={handleSearch}
                                enterButton
                                className='search'
                            />
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={filteredAppointments}
                        className="tabla font-small"
                        showSorterTooltip={{
                            title: 'Haga clic para ordenar',
                        }}
                        pagination={false}
                        scroll={{
                            y: 240,
                        }}
                    />
                    <Col span={24} className="inputCol">
                        <Row justify={'space-between'}>
                            <Col>
                                <Button className="volverButton" onClick={navigateToHome}>
                                    VOLVER
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default DentistManage;
