import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Input, DatePicker, Select, message } from 'antd';
import { EditOutlined, CheckOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/Contaduria.css';
import { updateOutcome, deleteOutcome, postOutcome, getOutcomesFijos, getOutcomesVariables } from '../services/OutcomesServices';
import moment from 'moment';
import 'moment/locale/es';

const Egresos = () => {
    const [egresosFijos, setEgresosFijos] = useState([]);
    const [egresosVariables, setEgresosVariables] = useState([]);
    const [tempEgresosFijos, setTempEgresosFijos] = useState([]);
    const [tempEgresosVariables, setTempEgresosVariables] = useState([]);
    const [editingFijos, setEditingFijos] = useState(false);
    const [editingVariables, setEditingVariables] = useState(false);
    const [editingRow, setEditingRow] = useState(null);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const mesHaceDosMeses = moment().subtract(2, 'months').format('MMMM YYYY');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEgresos = async () => {
            try {
                const fijos = await getOutcomesFijos();
                const variables = await getOutcomesVariables();

                if (Array.isArray(fijos)) {
                    const formattedFijos = fijos.map((egreso) => ({
                        ...egreso,
                        dayOfPayment: parseInt(egreso.date)
                    }));
                    setEgresosFijos(formattedFijos);
                } else {
                    console.error('La respuesta de egresos fijos no es un array');
                }

                if (Array.isArray(variables)) {
                    const formattedVariables = variables.map(egreso => ({
                        ...egreso,
                        date: moment(egreso.date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                    }));
                    setEgresosVariables(formattedVariables);
                } else {
                    console.error('La respuesta de egresos variables no es un array');
                }
            } catch (error) {
                console.error('Error al obtener los egresos:', error);
            }
        };

        fetchEgresos();
    }, [updateTrigger, editingFijos, editingVariables]);

    const handleBack = () => {
        navigate('/app/admin/contaduria');
    };

    const isRowValid = (egreso, isFijo) => {
        if (isFijo) {
            return egreso.detail && egreso.cost && egreso.dayOfPayment;
        } else {
            return egreso.detail && egreso.cost && egreso.date;
        }
    };

    const startDate = moment().subtract(2, 'months').startOf('month');
    const endDate = moment().add(2, 'months').endOf('month');

    const disabledDate = (current) => {
        return current < startDate|| current > endDate;
    };

    const handleEditarEgresoFijoInicio = (id) => {
        if (editingRow && editingRow !== id) {
            setEgresosFijos(tempEgresosFijos);
        }
        setEditingRow(id);
    };

    const handleEditarEgresoVariableInicio = (id) => {
        if (editingRow && editingRow !== id) {
            setEgresosVariables(tempEgresosVariables);
        }
        setEditingRow(id);
    };

    const toggleEditModeFijos = () => {
        if (editingFijos) {
            if (editingRow) {
                handleConfirmarEdicion();
            } else {
                setEgresosFijos(tempEgresosFijos);
            }
        } else {
            setTempEgresosFijos([...egresosFijos]);
        }
        setEditingFijos(!editingFijos);
        setEditingVariables(false);
        setEditingRow(null);
    };

    const toggleEditModeVariables = () => {
        if (editingVariables) {
            if (editingRow) {
                handleConfirmarEdicion();
            } else {
                setEgresosVariables(tempEgresosVariables);
            }
        } else {
            setTempEgresosVariables([...egresosVariables]);
        }
        setEditingVariables(!editingVariables);
        setEditingFijos(false);
        setEditingRow(null);
    };

    const handleAgregarEgresoFijo = async () => {
        const nuevoEgreso = {
            detail: 'detalle',
            cost: 100,
            type: 'fijo',
            dayOfPayment: moment().date()
        };

        try {
            const response = await postOutcome(nuevoEgreso.detail, nuevoEgreso.cost, nuevoEgreso.type, nuevoEgreso.dayOfPayment);
            if (response) {
                const updatedFijos = [...egresosFijos, {
                    ...response,
                    dayOfPayment: response.date
                }];
                setEgresosFijos(updatedFijos);
                setTempEgresosFijos(updatedFijos);
                setUpdateTrigger(!updateTrigger);
                message.success('Egreso fijo agregado exitosamente');
            }
        } catch (error) {
            console.error('Error al agregar egreso fijo:', error);
            message.error('Error al agregar egreso fijo');
        }
    };

    const handleAgregarEgresoVariable = async () => {
        const nuevoEgreso = {
            detail: 'detalle',
            cost: 100,
            type: 'variable',
            date: moment().format('YYYY-MM-DD')
        };
        try {
            const response = await postOutcome(nuevoEgreso.detail, nuevoEgreso.cost, nuevoEgreso.type, nuevoEgreso.date);
            if (response) {
                const updatedVariables = [...egresosVariables, response];
                setEgresosVariables(updatedVariables);
                setTempEgresosVariables(updatedVariables);
                setUpdateTrigger(!updateTrigger);
                message.success('Egreso variable agregado exitosamente');
            }
        } catch (error) {
            console.error('Error al agregar egreso variable:', error);
            message.error('Error al agregar egreso variable');
        }
    };

    const handleEditarEgresoFijo = (id, campo, valor) => {
        const egresosActualizados = egresosFijos.map(egreso =>
            egreso._id === id ? { ...egreso, [campo]: valor } : egreso
        );
        setEgresosFijos(egresosActualizados);
    };

    const handleEditarEgresoVariable = (id, campo, valor) => {
        const egresosActualizados = egresosVariables.map(egreso =>
            egreso._id === id ? { ...egreso, [campo]: valor } : egreso
        );
        setEgresosVariables(egresosActualizados);
    };

    const handleConfirmarEdicion = async () => {
        try {
            if (editingRow) {
                const isFijo = egresosFijos.some((egreso) => egreso._id === editingRow);
                const egreso = isFijo
                    ? egresosFijos.find((egreso) => egreso._id === editingRow)
                    : egresosVariables.find((egreso) => egreso._id === editingRow);

                if (!egreso.detail || (isFijo && !egreso.cost) || (!isFijo && !egreso.date)) {
                    message.error('Por favor, complete todos los campos.');
                    return;
                }

                const updatedData = isFijo
                    ? {
                        detail: egreso.detail,
                        cost: egreso.cost,
                        type: egreso.type,
                        date: egreso.dayOfPayment
                    }
                    : {
                        detail: egreso.detail,
                        cost: egreso.cost,
                        type: egreso.type,
                        date: egreso.date || null
                    };

                const response = await updateOutcome(egreso._id, updatedData);

                if (response) {
                    if (isFijo) {
                        const updatedFijos = egresosFijos.map((e) =>
                            e._id === editingRow ? { ...e, ...updatedData } : e
                        );
                        setEgresosFijos(updatedFijos);
                        setTempEgresosFijos(updatedFijos);
                    } else {
                        const updatedVariables = egresosVariables.map((e) =>
                            e._id === editingRow ? { ...e, ...updatedData } : e
                        );
                        setEgresosVariables(updatedVariables);
                        setTempEgresosVariables(updatedVariables);
                    }
                    setUpdateTrigger(!updateTrigger);
                    message.success('Egreso actualizado exitosamente');

                    setEditingRow(null);
                    if (editingFijos) {
                        setEditingFijos(false);
                    }
                    if (editingVariables) {
                        setEditingVariables(false);
                    }
                }
            }
        } catch (error) {
            console.error('Error al actualizar el egreso:', error);
            message.error('Error al actualizar el egreso');
        }
    };


    const handleCancelarEdicionFijos = () => {
        setEditingRow(null);
        setEgresosFijos(tempEgresosFijos);
    };

    const handleCancelarEdicionVariables = () => {
        setEditingRow(null);
        setEgresosVariables(tempEgresosVariables);
    };

    const handleBorrarEgreso = async (id, tipo) => {
        try {
            await deleteOutcome(id);

            if (tipo === 'fijo') {
                setEgresosFijos(egresosFijos.filter((egreso) => egreso._id !== id));
                setTempEgresosFijos(tempEgresosFijos.filter((egreso) => egreso._id !== id));
            } else if (tipo === 'variable') {
                setEgresosVariables(egresosVariables.filter((egreso) => egreso._id !== id));
                setTempEgresosVariables(tempEgresosVariables.filter((egreso) => egreso._id !== id));
            }
            setUpdateTrigger(!updateTrigger);
            message.success('Egreso eliminado exitosamente');
        } catch (error) {
            console.error('Error al eliminar el egreso:', error);
            message.error('Error al eliminar el egreso');
        }
    };


    return (
        <div className="home-client">
            <Row justify="center" className="cardsRow">
                <Col xl={22} lg={22} sm={22} xs={22}>
                    <Row justify="center">
                        <Col span={24} className="carddd2">
                            <Row justify="space-between">
                                <Col span={24} className='alinear'>
                                    <span className='textttt'>Egresos</span>
                                </Col>
                                <Col lg={10} md={24} sm={24} xs={24} className='outcome2'>
                                    <span className='textttt'>FIJOS</span>
                                    <div className="egresos-container">
                                        {egresosFijos.map((egreso) => (
                                            <Row key={egreso._id} className="egreso-item" justify={'center'} align="middle">
                                                <Col>
                                                    {editingFijos && editingRow === egreso._id ? (
                                                        <Input
                                                            placeholder="Detalle"
                                                            value={egreso.detail}
                                                            onChange={(e) => handleEditarEgresoFijo(egreso._id, 'detail', e.target.value)}
                                                            className='inputNombre'
                                                        />
                                                    ) : (
                                                        <span>{egreso.detail}</span>
                                                    )}
                                                </Col>
                                                <Col>
                                                    {editingFijos && editingRow === egreso._id ? (
                                                        <Select
                                                            placeholder="Día de pago"
                                                            value={egreso.dayOfPayment} // Ensure this is a number and corresponds correctly to the state
                                                            onChange={(value) => handleEditarEgresoFijo(egreso._id, 'dayOfPayment', value)}
                                                            className='inputDiaPago'
                                                            options={Array.from({ length: 31 }, (_, i) => ({
                                                                value: i + 1,
                                                                label: `Día ${i + 1}`
                                                            }))}
                                                        />
                                                    ) : (
                                                        <span>&nbsp; Día {egreso.dayOfPayment}</span>
                                                    )}
                                                </Col>
                                                <Col>
                                                    {editingFijos && editingRow === egreso._id ? (
                                                        <Input
                                                            placeholder="Costo"
                                                            value={egreso.cost}
                                                            onChange={(e) => handleEditarEgresoFijo(egreso._id, 'cost', e.target.value)}
                                                            className='inputCosto'
                                                        />
                                                    ) : (
                                                        <span>&nbsp; Costo ${egreso.cost}</span>
                                                    )}
                                                </Col>
                                                {editingFijos && editingRow === egreso._id ? (
                                                    <>
                                                        <Col>
                                                            <CheckOutlined
                                                                className='confirmer'
                                                                onClick={handleConfirmarEdicion}
                                                                disabled={!isRowValid(egreso, true)} // Deshabilitar si la fila no es válida
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <CloseCircleOutlined
                                                                className='canceler'
                                                                onClick={handleCancelarEdicionFijos}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <DeleteOutlined
                                                                className='deleter'
                                                                onClick={() => handleBorrarEgreso(egreso._id, 'fijo')}
                                                            />
                                                        </Col>
                                                    </>
                                                ) : editingFijos && ((
                                                    <Col>
                                                        <EditOutlined
                                                            style={{ fontSize: '20px', color: '#1890ff', cursor: 'pointer' }}
                                                            onClick={() => handleEditarEgresoFijoInicio(egreso._id)}
                                                        />
                                                    </Col>
                                                ))}
                                            </Row>
                                        ))}
                                        {editingFijos && (
                                            <Row justify="center">
                                                <Col>
                                                    <Button
                                                        type="primary"
                                                        className='buttonAdd'
                                                        onClick={handleAgregarEgresoFijo}
                                                    >
                                                        Agregar egreso fijo
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <Row className='chart-row'>
                                        <Button
                                            type="primary"
                                            className='buttonlogin'
                                            onClick={toggleEditModeFijos}
                                        >
                                            {editingFijos ? 'Guardar' : 'Editar'}
                                        </Button>
                                    </Row>
                                </Col>
                                <Col lg={10} md={24} sm={24} xs={24} className='outcome2'>
                                    <span className='textttt'>VARIABLES desde {mesHaceDosMeses}</span>
                                    <div className="egresos-container">
                                        {egresosVariables.map((egreso) => (
                                            <Row key={egreso._id} className="egreso-item" justify={'center'} align="middle">
                                                <Col>
                                                    {editingVariables && editingRow === egreso._id ? (
                                                        <Input
                                                            placeholder="Detalle"
                                                            value={egreso.detail}
                                                            onChange={(e) => handleEditarEgresoVariable(egreso._id, 'detail', e.target.value)}
                                                            className='inputNombre'
                                                        />
                                                    ) : (
                                                        <span>{egreso.detail}</span>
                                                    )}
                                                </Col>
                                                <Col>
                                                    {editingVariables && editingRow === egreso._id ? (
                                                        <DatePicker
                                                            placeholder="Fecha de pago"
                                                            value={egreso.date ? moment(egreso.date, 'YYYY-MM-DD') : null}
                                                            disabledDate={disabledDate}
                                                            onChange={(date) => handleEditarEgresoVariable(egreso._id, 'date', date ? date.format('YYYY-MM-DD') : null)}
                                                            className='inputFechaPago'
                                                            format="DD/MM/YYYY"
                                                        />
                                                    ) : (
                                                        <span>&nbsp; {egreso.date ? moment(egreso.date).format('DD/MM/YYYY') : ''}</span>
                                                    )}
                                                </Col>
                                                <Col>
                                                    {editingVariables && editingRow === egreso._id ? (
                                                        <Input
                                                            placeholder="Costo"
                                                            value={egreso.cost}
                                                            onChange={(e) => handleEditarEgresoVariable(egreso._id, 'cost', e.target.value)}
                                                            className='inputCosto2'
                                                        />
                                                    ) : (
                                                        <span>&nbsp; Costo ${egreso.cost}</span>
                                                    )}
                                                </Col>
                                                {editingVariables && editingRow === egreso._id ? (
                                                    <>
                                                        <Col>
                                                            <CheckOutlined
                                                                className='confirmer'
                                                                onClick={handleConfirmarEdicion}
                                                                disabled={!isRowValid(egreso, false)} // Deshabilitar si la fila no es válida
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <CloseCircleOutlined
                                                                className='canceler'
                                                                onClick={handleCancelarEdicionVariables}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <DeleteOutlined
                                                                className='deleter'
                                                                onClick={() => handleBorrarEgreso(egreso._id, 'variable')}
                                                            />
                                                        </Col>
                                                    </>
                                                ) : editingVariables && ((
                                                    <Col>
                                                        <EditOutlined
                                                            style={{ fontSize: '12px', color: '#1890ff', cursor: 'pointer' }}
                                                            onClick={() => handleEditarEgresoVariableInicio(egreso._id)}
                                                        />
                                                    </Col>
                                                ))}

                                            </Row>
                                        ))}
                                        {editingVariables && (
                                            <Row justify="center">
                                                <Col>
                                                    <Button
                                                        type="primary"
                                                        className='buttonAdd separar-bottom'
                                                        onClick={handleAgregarEgresoVariable}
                                                    >
                                                        Agregar egreso variable
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <Row className='chart-row '>
                                        <Button
                                            type="primary"
                                            className='buttonlogin '
                                            onClick={toggleEditModeVariables}
                                        >
                                            {editingVariables ? 'Guardar' : 'Editar'}
                                        </Button>
                                    </Row>
                                </Col>
                                <Col span={24} className="alinear separar-top">
                                    <Button type="primary" className="buttonlogin" onClick={handleBack}>
                                        Volver
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Egresos;
