import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Radio, Popconfirm, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { getPresupuestosByAppointmentId, confirmBudget } from '../services/AppointmentServices';
import { useUserRole } from '../utils/Cookies';
import '../assets/styles/PresupuestosInspect.css';

const PresupuestosInspect = () => {
    const { id: appointmentId } = useParams();
    const [presupuestosData, setPresupuestosData] = useState([null, null, null]);
    const [selectedPresupuestoIndex, setSelectedPresupuestoIndex] = useState(null);
    const [refresh, setRefresh] = useState(false);  // Estado adicional para forzar la recarga
    const navigate = useNavigate();
    const role = useUserRole();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPresupuestosByAppointmentId(appointmentId);
                if (data && data.presupuestos) {
                    const presupuestosArray = [null, null, null];
                    let confirmedIndex = null;
                    for (let i = 0; i < 3; i++) {
                        if (data.presupuestos[i]) {
                            presupuestosArray[i] = data.presupuestos[i];
                            if (data.presupuestos[i].status === 'confirmado') {
                                confirmedIndex = i;
                            }
                        }
                    }
                    setPresupuestosData(presupuestosArray);
                    if (confirmedIndex !== null) {
                        setSelectedPresupuestoIndex(confirmedIndex);
                    }
                } else {
                    setPresupuestosData([null, null, null]);
                }
            } catch (error) {
                console.error('Error fetching presupuestos data:', error);
            }
        };

        fetchData();
    }, [appointmentId, refresh]);  // Añadir `refresh` a las dependencias

    const handleCreateEditPresupuesto = (index) => {
        const existingPresupuesto = presupuestosData[index];
        const action = existingPresupuesto && Object.keys(existingPresupuesto).length > 0 ? 'editar' : 'crear';
        navigate(`/app/${role}/presupuesto/${action}/${appointmentId}/${index}`);
    };

    const handleConfirmPresupuesto = async () => {
        if (selectedPresupuestoIndex !== null && presupuestosData[selectedPresupuestoIndex]) {
            try {
                const confirmedIndex = selectedPresupuestoIndex;
                const response = await confirmBudget(appointmentId, confirmedIndex);
                setRefresh(prev => !prev);  // Cambiar el estado `refresh` para recargar los datos
                message.success('Presupuesto confirmado con éxito.');  // Mostrar mensaje de éxito
            } catch (error) {
                console.error('Error al confirmar el presupuesto:', error);
                message.error('Error al confirmar el presupuesto.');  // Mostrar mensaje de error
            }
        }
    };

    const renderPresupuesto = (presupuesto, index) => (
        <Col span={8} className="presupuesto-section" key={index}>
            <Row gutter={[16, 16]} style={{ height: '100%' }}>
                <Col span={24}>
                    <h3 className='textt'>Presupuesto {index + 1}</h3>
                    {presupuesto && Object.keys(presupuesto).length > 0 ? (
                        <div>
                            <p className='textt'>Precio Total: ${presupuesto.montoTotal}</p>
                            {presupuesto.tratamientos && presupuesto.tratamientos.length > 0 ? (
                                <ul className='list'>
                                    {presupuesto.tratamientos.map((tratamiento, idx) => (
                                        <li key={idx} className='textt'>
                                            {tratamiento.diente}: {tratamiento.tratamiento} - ${tratamiento.precio}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className='textt'>No hay tratamientos disponibles.</p>
                            )}
                        </div>
                    ) : (
                        <p className='textt'>No hay presupuesto</p>
                    )}
                </Col>
                {(role === 'dentista' || role === 'admin') && (
                    <Col span={24} className="rowbuttons">
                        <Row justify="center">
                            <Button
                                className='greenButton'
                                onClick={() => handleCreateEditPresupuesto(index)}
                            >
                                {presupuesto && Object.keys(presupuesto).length > 0 ? 'Editar' : 'Crear'}
                            </Button>
                        </Row>
                    </Col>
                )}
                {role === 'admin' && presupuesto && Object.keys(presupuesto).length > 0 && (
                    <>
                        <Col span={24} className="rowbuttons">
                            <Row>
                                <Col span={24}>
                                    {presupuesto.status === 'confirmado' && (
                                        <span className="text-confirmado">Actualmente confirmado</span>
                                    )}
                                </Col>
                                <Col span={24}>
                                    <Radio
                                        checked={selectedPresupuestoIndex === index}
                                        onChange={() => setSelectedPresupuestoIndex(index)}
                                    >
                                        Seleccionar este presupuesto
                                    </Radio>
                                </Col>
                            </Row>

                        </Col>
                    </>
                )}
            </Row>
        </Col>
    );

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={20} sm={22} xs={24} className="presupuestosCard">
                    <Row justify="center">
                        <h2 className='presupuestos-title'>Presupuestos</h2>
                    </Row>
                    <Row gutter={[16, 16]}>
                        {presupuestosData.map((presupuesto, index) => renderPresupuesto(presupuesto, index))}
                    </Row>
                    {role === 'admin' && (
                        <Row justify="center" className="button-row">
                            <Popconfirm
                                title="Estas seguro de que quieres confirmar este presupuesto?"
                                onConfirm={handleConfirmPresupuesto}
                                okText="Si"
                                cancelText="No"
                                disabled={selectedPresupuestoIndex === null || !presupuestosData[selectedPresupuestoIndex]}
                            >
                                <Button
                                    type="primary"
                                    className='confirmarButton'
                                    disabled={selectedPresupuestoIndex === null || !presupuestosData[selectedPresupuestoIndex]}
                                >
                                    Confirmar Presupuesto
                                </Button>
                            </Popconfirm>
                        </Row>
                    )}
                    <Row justify="center" className="button-row">
                        <Button className='agendarButton' onClick={() => navigate(`/app/${role}/historial`)}>Volver</Button>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default PresupuestosInspect;
