import React, { useEffect, useState } from 'react';
import { Badge, Calendar, Col, Popconfirm, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../assets/styles/Calendar.css';
import { getAppointmentsBySpecialistId, getAllAppointments } from '../services/AppointmentServices';
import { useUserId, useUserRole } from '../utils/Cookies';

const Calendario = () => {
    const navigate = useNavigate();
    const startDate = moment().startOf('day');
    const [appointments, setAppointments] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const userId = useUserId();
    const userRole = useUserRole();

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                let appointmentsData;
                if (userRole === 'dentista') {
                    appointmentsData = await getAppointmentsBySpecialistId(userId);
                } else if (userRole === 'admin' || userRole === 'secretaria') {
                    appointmentsData = await getAllAppointments();
                }

                if (Array.isArray(appointmentsData)) {
                    const normalizedData = appointmentsData.map(appointment => ({
                        ...appointment,
                        date: {
                            ...appointment.date,
                            start_time: appointment.date.start_time || appointment.date.startTime,
                            end_time: appointment.date.end_time || appointment.date.endTime,
                        },
                    }));
                    setAppointments(normalizedData);
                } else if (appointmentsData && appointmentsData.appointments) {
                    const normalizedData = appointmentsData.appointments.map(appointment => ({
                        ...appointment,
                        date: {
                            ...appointment.date,
                            start_time: appointment.date.start_time || appointment.date.startTime,
                            end_time: appointment.date.end_time || appointment.date.endTime,
                        },
                    }));
                    setAppointments(normalizedData);
                } else {
                    console.error('Appointments data structure is unexpected:', appointmentsData);
                }
            } catch (error) {
                console.error('Error fetching appointments:', error.message);
            }
        };

        fetchAppointments();
    }, [userId, userRole]);

    const disabledDate = (current) => {
        return current && current < startDate;
    };

    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };

    const headerRender = ({ value, onChange }) => {
        const currentMonth = moment().month();
        const currentYear = moment().year();
        const month = value.month();
        const year = value.year();
        const monthOptions = moment.months().map((monthName, index) => {
            if (year === currentYear && index < currentMonth) {
                return null;
            }
            return (
                <option key={index} value={index} disabled={year === currentYear && index < currentMonth}>
                    {monthName}
                </option>
            );
        });

        const yearOptions = [];
        for (let y = currentYear; y <= currentYear + 1; y++) {
            yearOptions.push(
                <option key={y} value={y} disabled={y < currentYear}>
                    {y}
                </option>
            );
        }

        const handleMonthChange = (event) => {
            const newMonth = parseInt(event.target.value, 10);
            const newValue = value.clone().month(newMonth);
            onChange(newValue);
        };

        const handleYearChange = (event) => {
            const newYear = parseInt(event.target.value, 10);
            const newValue = value.clone().year(newYear);
            onChange(newValue);
        };

        return (
            <div style={{ padding: 8 }}>
                <select value={month} onChange={handleMonthChange}>
                    {monthOptions}
                </select>
                <span style={{ marginLeft: 8 }}>
                    <select value={year} onChange={handleYearChange}>
                        {yearOptions}
                    </select>
                </span>
            </div>
        );
    };

    const getListData = (value) => {
        let listData = [];
        appointments.forEach(appointment => {
            const appointmentDate = moment(appointment.date.day);
            if (value.isSame(appointmentDate, 'day')) {
                listData.push({
                    type: appointment.status === 'Confirmada' ? 'success' : 'error',
                    content: `${appointment.treatment}`,
                });
            }
        });
        return listData;
    };

    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map((item, index) => (
                    <li key={index}>
                        <Badge status={item.type} text={<span className='calendardata'>{item.content}</span>} />
                    </li>
                ))}
            </ul>
        );
    };

    const onSelect = (value) => {
        setSelectedDate(value);
        setConfirmVisible(true);
    };

    const handleConfirm = () => {
        const formattedDate = selectedDate.format('YYYY-MM-DD');
        navigate(`/app/${userRole}/calendario/${formattedDate}`);
        setConfirmVisible(false);
    };

    const handleCancel = () => {
        setConfirmVisible(false);
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={22} sm={22} xs={22} className="CalendarCard">
                    <Popconfirm
                        title={`Quieres ver las citas del día ${selectedDate ? selectedDate.format('YYYY-MM-DD') : ''}?`}
                        visible={confirmVisible}
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                        okText="Sí"
                        cancelText="No"
                    >
                        <Calendar
                            headerRender={headerRender}
                            disabledDate={disabledDate}
                            dateCellRender={dateCellRender}
                            onPanelChange={onPanelChange}
                            onSelect={onSelect}
                        />
                    </Popconfirm>
                </Col>
            </Row>
        </div>
    );
};

export default Calendario;
