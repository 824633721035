import Cookies from 'universal-cookie';

const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const getProcedures = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get('access-token');

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/procedures`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error('Failed to fetch procedures');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al obtener procedimientos:', err.message);
        throw err;
    }
};

export const createProcedure = async (procedure) => {
    const cookies = new Cookies();
    const accessToken = cookies.get('access-token');

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/procedures/new`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(procedure)
        });

        if (!response.ok) {
            throw new Error('Failed to create procedure');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al crear procedimiento:', err.message);
        throw err;
    }
};

export const updateProcedure = async (id, updatedProcedure) => {
    const cookies = new Cookies();
    const accessToken = cookies.get('access-token');

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/procedures/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(updatedProcedure)
        });

        if (!response.ok) {
            throw new Error('Failed to update procedure');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al actualizar procedimiento:', err.message);
        throw err;
    }
};

export const deleteProcedure = async (id) => {
    const cookies = new Cookies();
    const accessToken = cookies.get('access-token');

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/procedures/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error('Failed to delete procedure');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al eliminar procedimiento:', err.message);
        throw err;
    }
};
