import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getAllAppointments } from '../services/AppointmentServices';
import { getUser } from '../services/UserServices';
import { useUserId } from '../utils/Cookies';

const { Search } = Input;

const AppointmentsManageAssist = () => {
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [users, setUsers] = useState({});
    const [searchText, setSearchText] = useState('');
    const userId = useUserId();

    useEffect(() => {
        const fetchAppointmentsAndUsers = async () => {
            try {
                const response = await getAllAppointments();
                const fetchedAppointments = response.appointments; 

                const confirmedAppointments = fetchedAppointments.filter(appointment => appointment.status === 'Confirmada');

                const uniqueUserIds = new Set();
                confirmedAppointments.forEach(appointment => {
                    uniqueUserIds.add(appointment.participants[0]); 
                    uniqueUserIds.add(appointment.participants[1]); 
                });

                const usersData = await Promise.all([...uniqueUserIds].map(id => getUser(id)));
                const usersMap = usersData.reduce((map, user) => {
                    map[user._id] = user.username;
                    return map;
                }, {});

                setUsers(usersMap);
                setAppointments(confirmedAppointments);
            } catch (error) {
                console.error('Error fetching appointments or users:', error);
            }
        };

        fetchAppointmentsAndUsers();
    }, []);

    const handleSearch = (value) => {
        setSearchText(value.toLowerCase());
    };

    const handleAnamnesis = (appointmentId, anamnesisExists) => {
        if (anamnesisExists) {
            navigate(`/app/secretaria/editar-anamnesis/${appointmentId}`);
        } else {
            navigate(`/app/secretaria/crear-anamnesis/${appointmentId}`);
        }
    };

    const filteredAppointments = appointments.filter(appointment => {
        const clientName = users[appointment.participants[0]]?.toLowerCase() || '';
        const specialistName = users[appointment.participants[1]]?.toLowerCase() || '';
        const date = new Date(appointment.date.day).toISOString().split('T')[0].toLowerCase();
        const startTime = new Date(appointment.date.start_time).toLocaleTimeString('es-ES', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).toLowerCase();
        const endTime = new Date(appointment.date.end_time).toLocaleTimeString('es-ES', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).toLowerCase();
        const time = `${startTime} - ${endTime}`;
        const treatment = appointment.treatment.toLowerCase();

        return (
            clientName.includes(searchText) ||
            specialistName.includes(searchText) ||
            date.includes(searchText) ||
            time.includes(searchText) ||
            treatment.includes(searchText)
        );
    });

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date',
            width: 100,
            sorter: (a, b) => new Date(a.date.day) - new Date(b.date.day),
            render: (text, record) => new Date(record.date.day).toISOString().split('T')[0]
        },
        {
            title: 'Hora',
            dataIndex: 'time',
            key: 'time',
            width: 120,
            render: (text, record) => {
                const startTime = new Date(record.date.start_time).toLocaleTimeString('es-ES', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                const endTime = new Date(record.date.end_time).toLocaleTimeString('es-ES', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                return `${startTime} - ${endTime}`;
            },
        },
        {
            title: 'Tratamiento',
            dataIndex: 'treatment',
            key: 'treatment',
            width: 110,
        },
        {
            title: 'Cliente',
            dataIndex: 'participants',
            key: 'participants',
            width: 150,
            render: (text) => <span>{users[text[0]] || text[0]}</span>,
        },
        {
            title: 'Especialista',
            dataIndex: 'participants',
            key: 'specialist',
            width: 150,
            render: (text) => <span>{users[text[1]] || text[1]}</span>,
        },
        {
            title: 'Anamnesis',
            key: 'anamnesis',
            width: 150,
            render: (text, record) => {
                const anamnesisExists = !!record.anamnesis;
                return (
                    <Button onClick={() => handleAnamnesis(record._id, anamnesisExists)}>
                        {anamnesisExists ? 'Editar' : 'Crear'}
                    </Button>
                );
            }
        }
    ];

    const navigateToHome = () => {
        navigate(`/app`);
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={18} sm={22} xs={22} className="recordCard">
                    <Row>
                        <h1 className="home-title">Estas son tus próximas citas</h1>
                    </Row>
                    <Row>
                        <Search
                            placeholder="Buscar en todas las columnas"
                            onSearch={handleSearch}
                            onChange={e => handleSearch(e.target.value)}
                            style={{ marginBottom: 16 }}
                        />
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={filteredAppointments}
                        className="tabla font-small"
                        showSorterTooltip={{
                            title: 'Haga clic para ordenar',
                        }}
                        pagination={false}
                        scroll={{
                            y: 240,
                        }}
                        rowKey="_id"
                    />
                    <Col span={24} className="inputCol">
                        <Row justify={'space-between'}>
                            <Col>
                                <Button className="volverButton" onClick={navigateToHome}>
                                    VOLVER
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default AppointmentsManageAssist;
