import React, { useState } from 'react';
import { Button, Col, Input, message, Row } from 'antd';
import { checkUserExists } from '../services/UserServices';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/Agendar.css';

const AdminInspect = () => {
    const navigate = useNavigate();

    const handleBack = async () => {
        navigate('/app')
    }

    const handleClientes = async () => {
        navigate('/app/admin/clientes')
    };

    const handleCitas = async () => {
        navigate('/app/admin/historial')
    };

    const handleProcedimientos = async () => {
        navigate('/app/admin/procedimientos')
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={12} sm={15} xs={20} className="gestionarCard">
                    <Row className='middle'>
                        <h1 className="home-title titleee">Gestione clientes, citas o procedimientos</h1>
                    </Row>
                    <Row className='padtop2' justify="center">
                        <Col>
                            <Row justify="center">

                                <Button className='agendarButton padleft' onClick={handleClientes}>
                                    Clientes
                                </Button>
                                <Button className='agendarButton padleft' onClick={handleCitas}>
                                    Citas
                                </Button>
                                <Button className='agendarButton padleft' onClick={handleProcedimientos}>
                                    Procedimientos
                                </Button>
                            </Row>
                            <Row justify="center padtop2 volverrr">
                                <Button className="greenButton " onClick={handleBack}>
                                    Volver
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AdminInspect;
