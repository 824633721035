import Cookies from "universal-cookie";

const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const getOutcomes = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/outcomes`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch outcomes');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al obtener outcomes:', err.message);
        throw err;
    }
};

export const getOutcomesVariables = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/outcomes/variables`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch variable outcomes');
        }

        const data = await response.json();
        return data.outcomes;

    } catch (err) {
        console.error('Error al obtener outcomes variables:', err.message);
        throw err;
    }
};

export const getOutcomesFijos = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/outcomes/fijos`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to fetch fixed outcomes');
        }

        const data = await response.json();
        return data.outcomes;

    } catch (err) {
        console.error('Error al obtener outcomes fijos:', err.message);
        throw err;
    }
};


export const postOutcome = async (detail, cost, type, date) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/outcomes`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                detail: detail,
                cost: cost,
                type: type,
                date: date
            })
        });

        if (!response.ok) {
            throw new Error('Failed to create outcome');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al crear outcome:', err.message);
        throw err;
    }
};

export const deleteOutcome = async (outcomeId) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");

    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/outcomes/delete/${outcomeId}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            credentials: "include"
        });

        if (!response.ok) {
            throw new Error('Failed to delete outcome');
        }

        const data = await response.json();
        return data;

    } catch (err) {
        console.error('Error al eliminar outcome:', err.message);
        throw err;
    }
};

export const updateOutcome = async (_id, updatedData) => {
    const cookies = new Cookies();
    const accessToken = cookies.get("access-token");
    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/outcomes/update/${_id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify(updatedData),
        });
        if (!response.ok) {
            throw new Error('Failed to update outcome');
        }
        return await response.json();
    } catch (error) {
        console.error('Error updating outcome:', error);
        throw error;
    }
};
