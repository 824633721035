import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Checkbox, Collapse, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { addPresupuestosToAppointment, getPresupuestosByAppointmentId } from '../services/AppointmentServices';
import { getProcedures } from '../services/ProceduresServices';
import '../assets/styles/Presupuesto.css';
import dientes from '../assets/images/tothmap.webp';

const dientesGrupo1 = {
    superiores: [18, 17, 16, 15, 14, 13, 12, 11],
    inferiores: [48, 47, 46, 45, 44, 43, 42, 41]
};

const dientesGrupo2 = {
    superiores: [21, 22, 23, 24, 25, 26, 27, 28],
    inferiores: [31, 32, 33, 34, 35, 36, 37, 38]
};

const PresupuestoCreate = () => {
    const { id: appointmentId, presupuestoIndex } = useParams();
    const currentPresupuestoIndex = parseInt(presupuestoIndex, 10);
    const navigate = useNavigate();
    const [selectedTeeth, setSelectedTeeth] = useState([]);
    const [selectedProcedures, setSelectedProcedures] = useState({});
    const [existingBudget, setExistingBudget] = useState(null);
    const [procedures, setProcedures] = useState([]);
    const [isVip, setIsVip] = useState(false);

    useEffect(() => {
        const fetchProcedures = async () => {
            try {
                const response = await getProcedures();
                if (response && response.procedures) {
                    setProcedures(response.procedures);
                }
            } catch (error) {
                console.error('Error fetching procedures:', error);
            }
        };

        fetchProcedures();
    }, []);

    useEffect(() => {
        const fetchPresupuesto = async () => {
            try {
                const presupuestos = await getPresupuestosByAppointmentId(appointmentId);
                if (presupuestos && presupuestos.presupuestos[currentPresupuestoIndex]) {
                    const presupuesto = presupuestos.presupuestos[currentPresupuestoIndex];
                    setExistingBudget(presupuesto);
                    const teeth = presupuesto.tratamientos.map(t => t.diente);
                    setSelectedTeeth(teeth);
                    const proceduresMap = {};
                    presupuesto.tratamientos.forEach(t => {
                        if (!proceduresMap[t.diente]) {
                            proceduresMap[t.diente] = [];
                        }
                        const proc = procedures.find(p => p.nombre === t.tratamiento);
                        if (proc) {
                            proceduresMap[t.diente].push(proc._id);
                        }
                    });
                    setSelectedProcedures(proceduresMap);
                }
            } catch (error) {
                console.error('Error fetching presupuesto:', error);
            }
        };

        fetchPresupuesto();
    }, [appointmentId, currentPresupuestoIndex, procedures]);

    const handleToothChange = (tooth, checked) => {
        setSelectedTeeth(prev => {
            const newTeeth = checked ? [...prev, tooth] : prev.filter(t => t !== tooth);
            
            // Remove procedures associated with the tooth if unchecked
            setSelectedProcedures(prevProcedures => {
                const newProcedures = { ...prevProcedures };
                if (!checked) {
                    delete newProcedures[tooth];
                }
                return newProcedures;
            });

            return newTeeth;
        });
    };

    const handleProcedureChange = (tooth, procedureId, checked) => {
        setSelectedProcedures(prev => {
            const newProcedures = { ...prev };
            if (!newProcedures[tooth]) {
                newProcedures[tooth] = [];
            }
            if (checked) {
                newProcedures[tooth] = [...newProcedures[tooth], procedureId];
            } else {
                newProcedures[tooth] = newProcedures[tooth].filter(id => id !== procedureId);
                // Remove tooth if it no longer has procedures
                if (newProcedures[tooth].length === 0) {
                    delete newProcedures[tooth];
                }
            }
            return newProcedures;
        });
    };

    const calculateTotal = () => {
        return Object.values(selectedProcedures).flat().reduce((acc, id) => {
            const procedure = procedures.find(p => p._id === id);
            const price = isVip && procedure?.precioVip != null ? procedure.precioVip : procedure?.precioEstandar;
            return acc + (price || 0);
        }, 0);
    };

    const total = calculateTotal();

    const handleSave = async () => {
        const tratamientosTotales = [];

        selectedTeeth.forEach(tooth => {
            const tratamientos = selectedProcedures[tooth]?.map(procId => {
                const procedure = procedures.find(p => p._id === procId);
                const price = isVip && procedure?.precioVip != null ? procedure.precioVip : procedure?.precioEstandar;
                return {
                    diente: tooth,
                    tratamiento: procedure?.nombre,
                    precio: price
                };
            }) || [];

            tratamientosTotales.push(...tratamientos.filter(t => t.tratamiento));
        });

        const montoTotal = tratamientosTotales.reduce((acc, t) => acc + (t.precio || 0), 0);

        if (montoTotal > 0 && tratamientosTotales.length > 0) {
            const presupuesto = { montoTotal, tratamientos: tratamientosTotales };

            try {
                const newPresupuestoIndex = currentPresupuestoIndex + 1;
                await addPresupuestosToAppointment(appointmentId, newPresupuestoIndex, presupuesto);
                navigate(`/app/dentista/presupuesto/${appointmentId}`);
                message.success('Presupuesto guardado con éxito');
            } catch (error) {
                console.error('Error al guardar el presupuesto:', error);
                message.error('Error al guardar el presupuesto: ' + error.message);
            }
        } else {
            message.warning('No se han seleccionado tratamientos válidos para guardar.');
        }
    };

    const handleBack = async () => {
        navigate(`/app/dentista/presupuesto/${appointmentId}`);
    }

    // Agrupar procedimientos por categoría
    const groupedProcedures = procedures.reduce((acc, procedure) => {
        const { categoria } = procedure;
        if (!acc[categoria]) {
            acc[categoria] = [];
        }
        acc[categoria].push(procedure);
        return acc;
    }, {});

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={18} sm={20} xs={23} className="PresupuestoCard">
                    <h2 className='title2'>Presupuesto</h2>
                    <Checkbox
                        checked={isVip}
                        onChange={e => setIsVip(e.target.checked)}
                    >
                        Usuario VIP
                    </Checkbox>
                    <div className='center'>
                        <img className='imagen' src={dientes} alt="numeracion de los dientes" />
                    </div>
                    <Row className="dental-chart">
                        <Col xl={11} sm={24} xs={24} className="dientes-grupo">
                            <div className="dientes-superiores">
                                {dientesGrupo1.superiores.map(tooth => (
                                    <Checkbox
                                        className='checkbox'
                                        key={tooth}
                                        checked={selectedTeeth.includes(tooth)}
                                        onChange={e => handleToothChange(tooth, e.target.checked)}
                                    >
                                        {tooth}
                                    </Checkbox>
                                ))}
                            </div>
                            <div className="dientes-inferiores">
                                {dientesGrupo1.inferiores.map(tooth => (
                                    <Checkbox
                                        key={tooth}
                                        checked={selectedTeeth.includes(tooth)}
                                        onChange={e => handleToothChange(tooth, e.target.checked)}
                                    >
                                        {tooth}
                                    </Checkbox>
                                ))}
                            </div>
                        </Col>
                        <Col xl={11} sm={24} xs={24} className="dientes-grupo">
                            <div className="dientes-superiores">
                                {dientesGrupo2.superiores.map(tooth => (
                                    <Checkbox
                                        key={tooth}
                                        checked={selectedTeeth.includes(tooth)}
                                        onChange={e => handleToothChange(tooth, e.target.checked)}
                                    >
                                        {tooth}
                                    </Checkbox>
                                ))}
                            </div>
                            <div className="dientes-inferiores">
                                {dientesGrupo2.inferiores.map(tooth => (
                                    <Checkbox
                                        key={tooth}
                                        checked={selectedTeeth.includes(tooth)}
                                        onChange={e => handleToothChange(tooth, e.target.checked)}
                                    >
                                        {tooth}
                                    </Checkbox>
                                ))}
                            </div>
                        </Col>
                    </Row>
                    {selectedTeeth.length > 0 && (
                        <div className="selected-teeth-grid">
                            {selectedTeeth.map(tooth => (
                                <div key={tooth} className="tooth-procedures">
                                    <h3>Diente {tooth}</h3>
                                    <Collapse>
                                        {Object.entries(groupedProcedures).map(([category, procedures]) => (
                                            <Collapse.Panel header={category} key={category}>
                                                {procedures.map(proc => (
                                                    <Checkbox
                                                        key={`${tooth}-${proc._id}`}
                                                        checked={selectedProcedures[tooth] && selectedProcedures[tooth].includes(proc._id)}
                                                        onChange={e => handleProcedureChange(tooth, proc._id, e.target.checked)}
                                                    >
                                                        {proc.nombre} - {isVip && proc.precioVip != null ? proc.precioVip : proc.precioEstandar}$
                                                    </Checkbox>
                                                ))}
                                            </Collapse.Panel>
                                        ))}
                                    </Collapse>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="total">TOTAL: {total}$</div>
                    <div className="buttons2">
                        <Button className='agendarButton' onClick={handleBack}>VOLVER</Button>
                        <Button className='greenButton' onClick={handleSave}>CONTINUAR</Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default PresupuestoCreate;
