import React from 'react';
import {Navigate} from 'react-router-dom';
import Cookies from "universal-cookie";

const PrivateRoute = ({children}) => {
    const cookies = new Cookies(null, {path: "/"});
    const isLoggedIn = !!cookies.get('access-token');

    return isLoggedIn ? (
        <React.Fragment>{children}</React.Fragment>
    ) : (
        <Navigate to="/404"/>
    );
};

export default PrivateRoute;
