import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Menu, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import logo from '../assets/images/logo.webp';
import '../assets/styles/Navbar.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode'; // Correct import statement for jwtDecode

const AppNavbar = () => {
    const cookies = new Cookies();
    const [collapsed, setCollapsed] = useState(false);
    const [section1Name, setSection1Name] = useState('');
    const [section2Name, setSection2Name] = useState('');
    const navigate = useNavigate();
    const user = cookies.get('access-token');
    let userInfo = '';
    try {
        userInfo = jwtDecode(user);
    } catch (error) {
        console.log('No token');
    }

    const getSectionNames = (role) => {
        switch (role) {
            case 'cliente':
                setSection1Name('historial');
                setSection2Name('citas');
                break;
            case 'dentista':
                setSection1Name('calendario');
                setSection2Name('historial');
                break;
            case 'admin':
                setSection1Name('calendario');
                setSection2Name('dentistas');
                break;
            case 'secretaria':
                setSection1Name('calendario');
                setSection2Name('citas');
                break;
            default:
                setSection1Name('');
                setSection2Name('');
                break;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setCollapsed(window.innerWidth <= 780);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getSectionNames(userInfo?.role);
    }, [userInfo]);

    const navigateToSection = (sectionName) => {
        if (userInfo?.role) {
            navigate(`/app/${userInfo.role}/${sectionName}`);
        }
    };

    const navigateToHome = () => {
        navigate(`/app`);
    };

    const handleLogout = () => {
        cookies.remove('access-token');
        navigate('/login');
    };

    const menu = (
        <Menu>
            <Menu.Item key="inicio" onClick={() => navigateToSection(section1Name)}>{section1Name}</Menu.Item>
            <Menu.Item key="servicios" onClick={() => navigateToSection(section2Name)}>{section2Name}</Menu.Item>
            <Menu.Item key="logout" onClick={handleLogout}>Cerrar Sesión</Menu.Item>
        </Menu>
    );

    if (!userInfo?.role) {
        return null;
    }

    return (
        <Row className="Row" justify="space-between">
            <Col lg={4} className='logocol'>
                <img src={logo} alt="logo" className="logo" onClick={navigateToHome} />
            </Col>
            <Col xl={20} className={`colNav ${collapsed && 'collapsed'}`}>
                <Row justify="end" className="alignvert">
                    {collapsed ? (
                        <Col>
                            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                                <MenuOutlined className="menuicon" />
                            </Dropdown>
                        </Col>
                    ) : (
                        <>
                            <Col>
                                <span className="NavItem2" onClick={() => navigateToSection(section1Name)}>
                                    {section1Name.toUpperCase()}
                                </span>
                            </Col>
                            <Col>
                                <span className="NavItem2" onClick={() => navigateToSection(section2Name)}>
                                    {section2Name.toUpperCase()}
                                </span>
                            </Col>
                            <Col>
                                <span className="NavItem2" onClick={handleLogout}>
                                    CERRAR SESIÓN
                                </span>
                            </Col>
                        </>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default AppNavbar;
