import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getOutcomesFijos, getOutcomesVariables } from '../services/OutcomesServices';
import { getIncomes } from '../services/AppointmentServices';

// Función para copiar las primeras 4 filas de una hoja a otra
function copySheetData(sourceSheet, targetSheet) {
    // Lógica para copiar las primeras 4 filas (o según sea necesario)
    // Asumiendo que `sourceSheet` y `targetSheet` son objetos de hojas de Excel válidos
    const range = XLSX.utils.decode_range(sourceSheet['!ref']);
    for (let R = 0; R <= 3; R++) { // Primeras 4 filas
        for (let C = range.s.c; C <= range.e.c; C++) {
            const address = XLSX.utils.encode_cell({ r: R, c: C });
            if (sourceSheet[address]) {
                targetSheet[address] = { ...sourceSheet[address] };
            }
        }
    }
}

export const generateExcel = async () => {
    const workbook = XLSX.utils.book_new();

    // Crear la hoja de Gastos
    const worksheetGastos = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.book_append_sheet(workbook, worksheetGastos, 'Planilla de Gastos');

    const today = new Date();
    let startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    let endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);

    const getWeeksInMonth = (start, end) => {
        const weeks = [];
        let weekStart = new Date(start);

        while (weekStart <= end) {
            let weekEnd = new Date(weekStart);
            weekEnd.setDate(weekStart.getDate() + 6);

            if (weekEnd > end) {
                weekEnd = end;
            }

            weeks.push({
                start: weekStart.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' }),
                end: weekEnd.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' }),
            });

            weekStart.setDate(weekEnd.getDate() + 1);
        }

        return weeks;
    };

    const allWeeks = [];
    let currentMonth = startDate.getMonth();
    let currentYear = startDate.getFullYear();

    while (new Date(currentYear, currentMonth) <= endDate) {
        const monthStart = new Date(currentYear, currentMonth, 1);
        const monthEnd = new Date(currentYear, currentMonth + 1, 0);

        const weeks = getWeeksInMonth(monthStart, monthEnd);

        allWeeks.push({
            month: monthStart.toLocaleString('es-ES', { month: 'long' }).toUpperCase(),
            weeks: weeks,
        });

        currentMonth++;
        if (currentMonth > 11) {
            currentMonth = 0;
            currentYear++;
        }
    }

    const monthHeaders = ['Fecha', '', ''];
    const weekHeaders = ['Grupo', 'Sub Gr', 'Concepto'];

    allWeeks.forEach(monthData => {
        monthHeaders.push(monthData.month);
        monthHeaders.push(...Array(monthData.weeks.length - 1).fill(''));
        monthData.weeks.forEach((week, index) => {
            weekHeaders.push(`SEMANA ${index + 1}`);
        });
    });

    const initialRows = [
        monthHeaders,
        weekHeaders,
        [''],
        [''],
        [''],
        ['Gastos', ''],
        ['', 'Fijos'],
    ];

    XLSX.utils.sheet_add_json(worksheetGastos, initialRows, { origin: 'A1', skipHeader: true });

    const outcomesFijos = await getOutcomesFijos();

    let startRow = 8;
    outcomesFijos.forEach(outcome => {
        XLSX.utils.sheet_add_json(worksheetGastos, [[outcome.detail]], { origin: `C${startRow}`, skipHeader: true });

        const formattedCost = `${outcome.cost} €`;

        let colIndex = 3;
        allWeeks.forEach(monthData => {
            monthData.weeks.forEach((week, index) => {
                const weekStartDate = new Date(currentYear, currentMonth, parseInt(week.start.split('/')[0]));
                const weekEndDate = new Date(currentYear, currentMonth, parseInt(week.end.split('/')[0]));

                if (outcome.date >= weekStartDate.getDate() && outcome.date <= weekEndDate.getDate()) {
                    XLSX.utils.sheet_add_json(worksheetGastos, [[formattedCost]], { origin: `${String.fromCharCode(65 + colIndex)}${startRow}`, skipHeader: true });
                }

                colIndex++;
            });
        });

        startRow++;
    });

    const emptyRow = ['', '', ''];
    XLSX.utils.sheet_add_json(worksheetGastos, [emptyRow], { origin: `A${startRow + 1}`, skipHeader: true });

    const variablesHeader = ['', 'Variables', ''];
    XLSX.utils.sheet_add_json(worksheetGastos, [variablesHeader], { origin: `A${startRow + 1}`, skipHeader: true });

    const outcomesVariables = await getOutcomesVariables();

    let variableStartRow = startRow + 2;
    outcomesVariables.forEach(outcome => {
        XLSX.utils.sheet_add_json(worksheetGastos, [[outcome.detail]], { origin: `C${variableStartRow}`, skipHeader: true });

        const formattedCost = `${outcome.cost} €`;
        const outcomeDate = new Date(outcome.date);

        const outcomeMonthIndex = outcomeDate.getMonth();
        const outcomeYear = outcomeDate.getFullYear();

        let colIndex = 3;
        allWeeks.forEach(monthData => {
            if (monthData.month.toUpperCase() === outcomeDate.toLocaleString('es-ES', { month: 'long' }).toUpperCase()) {
                monthData.weeks.forEach((week, index) => {
                    const weekStartDate = new Date(outcomeYear, outcomeMonthIndex, parseInt(week.start.split('/')[0]));
                    const weekEndDate = new Date(outcomeYear, outcomeMonthIndex, parseInt(week.end.split('/')[0]));

                    if (outcomeDate >= weekStartDate && outcomeDate <= weekEndDate) {
                        XLSX.utils.sheet_add_json(worksheetGastos, [[formattedCost]], { origin: `${String.fromCharCode(65 + colIndex)}${variableStartRow}`, skipHeader: true });
                    }

                    colIndex++;
                });
            } else {
                colIndex += monthData.weeks.length;
            }
        });

        variableStartRow++;
    });

    const lastRow = variableStartRow;
    const totalStartRow = lastRow + 1;
    const totalRow = Array.from({ length: monthHeaders.length - 3 }, () => 0);
    const weekColumnMapping = {};

    let colIndex = 3;
    allWeeks.forEach(monthData => {
        monthData.weeks.forEach((week, weekIndex) => {
            weekColumnMapping[`${week.start} AL ${week.end}`] = colIndex;
            colIndex++;
        });
    });

    allWeeks.forEach((monthData, monthIndex) => {
        monthData.weeks.forEach((week, weekIndex) => {
            const weekStartCol = weekColumnMapping[`${week.start} AL ${week.end}`];
            let total = 0;
            for (let row = 7; row < variableStartRow; row++) {
                const cellValue = worksheetGastos[XLSX.utils.encode_cell({ c: weekStartCol, r: row })]?.v || '0 €';
                total += parseFloat(cellValue.replace(' €', '')) || 0;
            }
            totalRow[weekIndex + monthData.weeks.length * monthIndex] = total;
        });
    });

    XLSX.utils.sheet_add_json(worksheetGastos, [['TOTAL', '', '', ...totalRow.map(value => `${value} €`)]], { origin: `A${totalStartRow}`, skipHeader: true });

    colIndex = 3;
    allWeeks.forEach(monthData => {
        monthData.weeks.forEach(week => {
            XLSX.utils.sheet_add_json(worksheetGastos, [[`${week.start} AL ${week.end}`]], { origin: `${String.fromCharCode(65 + colIndex)}4`, skipHeader: true });
            colIndex++;
        });
    });

    // Crear la hoja de Ingresos
    const worksheetIngresos = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.book_append_sheet(workbook, worksheetIngresos, 'Planilla de Ingresos');

    // Copiar las primeras 4 filas de la hoja de Gastos a la hoja de Ingresos
    copySheetData(worksheetGastos, worksheetIngresos);

    // Obtener ingresos
    const incomeData = await getIncomes();
    const incomes = incomeData.incomes || [];

    if (incomes.length > 0) {
        let incomeStartRow = 8; // Fila de inicio para los ingresos

        incomes.forEach(income => {
            // Ingreso formateado
            const formattedIncome = `${income.confirmedBudget.montoTotal} €`;
            const incomeDate = new Date(income.date.day);

            // Ajustar la columna para las semanas
            let colIndex = 3; // Columna inicial (C)
            allWeeks.forEach(monthData => {
                if (monthData.month.toUpperCase() === incomeDate.toLocaleString('es-ES', { month: 'long' }).toUpperCase()) {
                    monthData.weeks.forEach((week, index) => {
                        const [weekStartDay] = week.start.split('/');
                        const [weekEndDay] = week.end.split('/');
                        const weekStartDate = new Date(incomeDate.getFullYear(), incomeDate.getMonth(), parseInt(weekStartDay));
                        const weekEndDate = new Date(incomeDate.getFullYear(), incomeDate.getMonth(), parseInt(weekEndDay));

                        if (incomeDate >= weekStartDate && incomeDate <= weekEndDate) {
                            XLSX.utils.sheet_add_json(worksheetIngresos, [
                                [formattedIncome]
                            ], { origin: `${String.fromCharCode(65 + colIndex)}${incomeStartRow}`, skipHeader: true });
                        }

                        colIndex++;
                    });
                } else {
                    colIndex += monthData.weeks.length;
                }
            });

            incomeStartRow++;
        });

        // Agregar etiqueta "Ingresos" en la misma columna que el total pero 3 filas más arriba
        const totalColumn = 3; // Columna para el total (C)
        const labelRow = incomeStartRow + 1; // Fila para la etiqueta "Ingresos"
        const totalStartRow = labelRow + 1; // Fila para la fila de totales

        // Insertar la etiqueta "Ingresos" en la columna del total
        XLSX.utils.sheet_add_json(worksheetIngresos, [['Ingresos']], { origin: `A${6}`, skipHeader: true });

        // Calcular los totales
        const totalRow = Array.from({ length: monthHeaders.length - 3 }, () => 0);
        const weekColumnMapping = {};

        let colIndex = 3;
        allWeeks.forEach(monthData => {
            monthData.weeks.forEach((week, weekIndex) => {
                weekColumnMapping[`${week.start} AL ${week.end}`] = colIndex;
                colIndex++;
            });
        });

        allWeeks.forEach((monthData, monthIndex) => {
            monthData.weeks.forEach((week, weekIndex) => {
                const weekStartCol = weekColumnMapping[`${week.start} AL ${week.end}`];
                let total = 0;
                for (let row = 7; row < incomeStartRow; row++) {
                    const cellValue = worksheetIngresos[XLSX.utils.encode_cell({ c: weekStartCol, r: row })]?.v || '0 €';
                    total += parseFloat(cellValue.replace(' €', '')) || 0;
                }
                totalRow[weekIndex + monthData.weeks.length * monthIndex] = total;
            });
        });

        XLSX.utils.sheet_add_json(worksheetIngresos, [['TOTAL', '', '', ...totalRow.map(value => `${value} €`)]], { origin: `A${totalStartRow}`, skipHeader: true });

        colIndex = 3;
        allWeeks.forEach(monthData => {
            monthData.weeks.forEach(week => {
                XLSX.utils.sheet_add_json(worksheetIngresos, [[`${week.start} AL ${week.end}`]], { origin: `${String.fromCharCode(65 + colIndex)}4`, skipHeader: true });
                colIndex++;
            });
        });
    }

    const worksheetResumen = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.book_append_sheet(workbook, worksheetResumen, 'Resumen');

    const totalEgresosFijos = outcomesFijos.reduce((total, outcome) => total + parseFloat(outcome.cost), 0);
    const totalEgresosVariables = outcomesVariables.reduce((total, outcome) => total + parseFloat(outcome.cost), 0);
    const totalEgresos = totalEgresosFijos * 4 + totalEgresosVariables;

    // Procesar los datos de ingresos
    const totalIngresos = incomes.reduce((total, income) => total + parseFloat(income.confirmedBudget.montoTotal), 0);

    // Agregar datos a la hoja de resumen
    const resumenData = [
        ['Resumen Financiero'],
        ['Tipo', 'Total'],
        ['Ingresos', `${totalIngresos} €`],
        ['Egresos Fijos', `${totalEgresosFijos * 4} €`],
        ['Egresos Variables', `${totalEgresosVariables} €`],
        ['Total Egresos', `${totalEgresos} €`],
        ['Saldo Neto', `${totalIngresos - totalEgresos} €`],
    ];

    XLSX.utils.sheet_add_aoa(worksheetResumen, resumenData, { origin: 'A1' });


    // Descargar el archivo de Excel
    const workbookArray = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const workbookBlob = new Blob([workbookArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(workbookBlob, 'Reporte_Financiero.xlsx');
};
