import React, { useState } from 'react';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { updatePassword } from '../services/UserServices';
import '../assets/styles/Login.css';

function ResetPassword() {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const userId = searchParams.get('userId');

    const handleFormSubmit = async () => {
        try {
            setConfirmLoading(true);
            const values = await form.validateFields();

            if (values.newPassword !== values.confirmPassword) {
                message.error('Las contraseñas no coinciden.');
                setConfirmLoading(false);
                return;
            }
            await updatePassword(userId, token, values.newPassword);

            message.success('Contraseña restablecida con éxito.');
            navigate('/login');
        } catch (error) {
            console.error('Error al restablecer la contraseña:', error);
            message.error('Error al restablecer la contraseña. Por favor, inténtalo de nuevo.');
            setConfirmLoading(false);
        }
    };

    return (
        <Form
            form={form}
            name="reset-password-form"
        >
            <div className="loginalign">
                <Row className="maprow3">
                    <Col span={20} className="card-container">
                        <Card bordered={true}>
                            <span className="title">
                                Restablecer Contraseña
                            </span>
                            <Form.Item className="formitem1" name="newPassword"
                                rules={[{ required: true, message: 'Ingrese su nueva contraseña' }]}>
                                <Input.Password
                                    placeholder="Nueva Contraseña"
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item className="formitem2" name="confirmPassword"
                                rules={[{ required: true, message: 'Confirme su nueva contraseña' }]}>
                                <Input.Password
                                    placeholder="Confirmar Contraseña"
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Button 
                                type="primary" 
                                shape="round" 
                                className="buttonlogin" 
                                htmlType="submit"
                                onClick={handleFormSubmit}
                                loading={confirmLoading}
                            >
                                Restablecer
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Form>
    );
}

export default ResetPassword;