import React, { useState } from 'react';
import { Col, Row, Input, Button, message, Upload } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { updateValoracion } from '../services/AppointmentServices';
import { PlusOutlined } from '@ant-design/icons';
import '../assets/styles/ValoracionCreation.css';

const ValoracionCreation = () => {
    const { id: appointmentId } = useParams();
    const [observaciones, setObservaciones] = useState('');
    const [imagenes, setImagenes] = useState([]);
    const navigate = useNavigate();

    const handleFinish = async () => {
        if (observaciones.trim() === '') {
            message.error('El campo "Observaciones" es obligatorio');
            return;
        }

        const formData = new FormData();
        formData.append('observaciones', observaciones);

        imagenes.forEach(file => {
            formData.append('imagenes', file.originFileObj);
        });

        try {
            await updateValoracion(appointmentId, formData);
            message.success('Valoración creada con éxito');
            navigate(`/app/dentista/historial`);
        } catch (error) {
            console.error('Error al actualizar la valoración:', error);
            message.error('Error al actualizar la valoración');
        }
    };

    const handleVolver = () => {
        navigate('/app/dentista/historial');
    };

    const handleFileChange = ({ fileList }) => {
        setImagenes(fileList);
    };

    const beforeUpload = (file) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            message.error('Solo se permiten imágenes');
        }
        return isImage;
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={8} sm={12} xs={16} className="valoracionCard">
                    <Row justify="center">
                        <h2>Cargado de Valoración Clínica</h2>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Input.TextArea
                                rows={4}
                                placeholder="Observaciones"
                                value={observaciones}
                                onChange={e => setObservaciones(e.target.value)}
                                style={{ marginBottom: 20 }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Upload
                                fileList={imagenes}
                                onChange={handleFileChange}
                                beforeUpload={beforeUpload}
                                multiple
                                listType="picture-card"
                                showUploadList={{ showRemoveIcon: true, showPreviewIcon: true }}
                                accept="image/*"
                                customRequest={({ file, onSuccess }) => {
                                    onSuccess(null, file);
                                }}
                            >
                                {imagenes.length >= 8 ? null : (
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Subir</div>
                                    </div>
                                )}
                            </Upload>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Button className='agendarButton lastRow' onClick={handleVolver}>
                                Volver
                            </Button>
                        </Col>
                        <Col span={12} className='rightbutton'>
                            <Button className='agendarButton lastRow' onClick={handleFinish}>
                                FINALIZAR
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ValoracionCreation;
