import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, Modal, Form, Input, Select, message, Popconfirm } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getProcedures, createProcedure, updateProcedure, deleteProcedure } from '../services/ProceduresServices';
import '../assets/styles/Procedimientos.css';

const { Option } = Select;

const Procedimientos = () => {
    const [procedures, setProcedures] = useState([]);
    const [filteredProcedures, setFilteredProcedures] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentProcedure, setCurrentProcedure] = useState(null);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    // Example categories; replace these with your actual categories.
    const categories = ['PROCEDIMIENTOS', 'ORTODONCIA', 'CIRURGIA', 'IMPLANTE', 'BLANQUEAMIENTO', 'CARILLAS ', 'PERIODONCIA ', 'PRÓTESIS ', 'BRUXISMO ', 'ENDODONCIA ', 'OTROS'];

    useEffect(() => {
        loadProcedures();
    }, []);

    useEffect(() => {
        setFilteredProcedures(
            procedures.filter(procedure =>
                procedure.nombre.toLowerCase().includes(searchValue.toLowerCase())
            )
        );
    }, [searchValue, procedures]);

    const loadProcedures = async () => {
        try {
            const data = await getProcedures();
            setProcedures(data.procedures);
        } catch (error) {
            message.error('Error al cargar los procedimientos');
            console.error('Error al cargar los procedimientos:', error.message);
        }
    };

    const handleAddProcedure = () => {
        setCurrentProcedure(null);
        form.resetFields();
        setIsModalVisible(true);
    };

    const handleEditProcedure = (procedure) => {
        setCurrentProcedure(procedure);
        form.setFieldsValue(procedure);
        setIsModalVisible(true);
    };

    const handleDeleteProcedure = async (id) => {
        try {
            await deleteProcedure(id);
            loadProcedures();
            message.success('Procedimiento eliminado exitosamente');
        } catch (error) {
            message.error('Error al eliminar el procedimiento');
            console.error('Error al eliminar procedimiento:', error.message);
        }
    };

    const confirmDelete = (id) => {
        handleDeleteProcedure(id);
    };

    const handleSubmit = async (values) => {
        const payload = {
            ...values,
            precioEstandar: parseFloat(values.precioEstandar),
            precioVip: values.precioVip ? parseFloat(values.precioVip) : undefined,
        };

        try {
            if (currentProcedure) {
                await updateProcedure(currentProcedure._id, payload);
                message.success('Procedimiento actualizado exitosamente');
            } else {
                await createProcedure(payload);
                message.success('Procedimiento creado exitosamente');
            }
            loadProcedures();
            setIsModalVisible(false);
        } catch (error) {
            message.error('Error al guardar el procedimiento');
            console.error('Error al guardar el procedimiento:', error.message);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={16} sm={24} xs={24} className='container-procedures'>
                    <Input
                        placeholder="Buscar por nombre"
                        value={searchValue}
                        onChange={handleSearchChange}
                        style={{ marginBottom: 16, width: '100%' }}
                    />
                    <Row gutter={16}>
                        {filteredProcedures.map((procedure) => (
                            <Col key={procedure._id} lg={8} sm={12} xs={24}>
                                <Card
                                    className="procedimiento-card"
                                    title={procedure.nombre}
                                    bordered={false}
                                    actions={[
                                        <Button onClick={() => handleEditProcedure(procedure)} type="link">Editar</Button>,
                                        <Popconfirm
                                            title="¿Estás seguro de eliminar este procedimiento?"
                                            onConfirm={() => confirmDelete(procedure._id)}
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Button type="link" danger>Eliminar</Button>
                                        </Popconfirm>
                                    ]}
                                >
                                    <div className="card-content">Precio Estándar: {procedure.precioEstandar}</div>
                                    {procedure.precioVip && (
                                        <div className="card-content">Precio VIP: {procedure.precioVip}</div>
                                    )}
                                    <div className="card-content">Categoría: {procedure.categoria}</div>
                                </Card>
                            </Col>
                        ))}
                        <Col lg={8} sm={12} xs={24}>
                            <Card
                                className="procedimiento-card button-style"
                                bordered={false}
                                onClick={handleAddProcedure}
                                style={{ cursor: 'pointer', textAlign: 'center' }}
                            >
                                <div className="card-content button-style-color">Agregar Procedimiento</div>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify={'end'}>
                <Button
                    type="default"
                    onClick={() => navigate('/app')}
                    className="volver-button2"
                >
                    Volver
                </Button>
            </Row>

            <Modal
                title={currentProcedure ? 'Editar Procedimiento' : 'Agregar Procedimiento'}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={currentProcedure}
                >
                    <Form.Item
                        name="nombre"
                        label="Nombre"
                        rules={[{ required: true, message: 'Por favor ingrese el nombre del procedimiento' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="precioEstandar"
                        label="Precio Estándar"
                        rules={[{ required: true, message: 'Por favor ingrese el precio estándar' }]}
                    >
                        <Input
                            type="text"
                            onChange={(e) => form.setFieldsValue({ precioEstandar: e.target.value.replace(',', '.') })}
                        />
                    </Form.Item>
                    <Form.Item
                        name="precioVip"
                        label="Precio VIP"
                    >
                        <Input
                            type="text"
                            onChange={(e) => form.setFieldsValue({ precioVip: e.target.value.replace(',', '.') })}
                        />
                    </Form.Item>
                    <Form.Item
                        name="categoria"
                        label="Categoría"
                        rules={[{ required: true, message: 'Por favor seleccione una categoría' }]}
                    >
                        <Select placeholder="Seleccione una categoría">
                            {categories.map(category => (
                                <Option key={category} value={category}>
                                    {category}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {currentProcedure ? 'Actualizar' : 'Agregar'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Procedimientos;
