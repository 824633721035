import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, Input, message, Popconfirm, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteUser, getUser, updateUserByAdmin } from '../services/UserServices';
import { getProcedures } from '../services/ProceduresServices';
import '../assets/styles/IndividualDentistManage.css';
import moment from "moment-timezone";
import { Select } from 'antd';

const { Option } = Select;
const { Panel } = Collapse;

const daysOfWeekInSpanish = {
    Monday: 'Lunes',
    Tuesday: 'Martes',
    Wednesday: 'Miércoles',
    Thursday: 'Jueves',
    Friday: 'Viernes',
    Saturday: 'Sábado',
    Sunday: 'Domingo'
};

const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const isValidTime = (time) => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return regex.test(time);
};

const IndividualDentistManage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [dentist, setDentist] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [originalDentist, setOriginalDentist] = useState(null);
    const [procedures, setProcedures] = useState([]);

    useEffect(() => {
        const fetchDentist = async () => {
            try {
                const fetchedDentist = await getUser(id);

                if (!fetchedDentist) {
                    message.error('No se encontró ningún dentista con este ID.');
                    return;
                }

                // Asignar valores por defecto si algunas propiedades no existen
                fetchedDentist.workingHours = fetchedDentist.workingHours?.map(hours => ({
                    ...hours,
                    startTime: hours.startTime ? hours.startTime.split('T')[1].slice(0, 5) : '', // Verifica si existe startTime
                    endTime: hours.endTime ? hours.endTime.split('T')[1].slice(0, 5) : '',     // Verifica si existe endTime
                    isHidden: false
                })) || [];

                fetchedDentist.vacations = fetchedDentist.vacations?.map(vacation => ({
                    ...vacation,
                    startDate: vacation.startDate ? vacation.startDate.split('T')[0].split('-').reverse().join('-') : '', // Verifica si existe startDate
                    endDate: vacation.endDate ? vacation.endDate.split('T')[0].split('-').reverse().join('-') : ''        // Verifica si existe endDate
                })) || [];

                fetchedDentist.treatments = fetchedDentist.treatments || [];

                fetchedDentist.NIE = fetchedDentist.NIE || '';
                fetchedDentist.username = fetchedDentist.username || '';
                fetchedDentist.email = fetchedDentist.email || '';
                fetchedDentist.phoneNumber = fetchedDentist.phoneNumber || '';

                setDentist(fetchedDentist);
                setOriginalDentist(JSON.parse(JSON.stringify(fetchedDentist)));
            } catch (error) {
                console.error('Error fetching dentist:', error);
                message.error('Error al obtener el dentista.');
            }
        };

        fetchDentist();
    }, [id]);

    useEffect(() => {
        const fetchProcedures = async () => {
            try {
                const response = await getProcedures();
                if (response && response.procedures) {
                    setProcedures(response.procedures);
                }
            } catch (error) {
                console.error('Error fetching procedures:', error);
            }
        };
        fetchProcedures();
    }, []);

    const getUniqueCategories = () => {
        const categories = procedures.map(procedure => procedure.categoria);
        return [...new Set(categories)];  // Use Set to get unique categories
    };

    // Handle category change in the treatment selection
    const handleTreatmentChange = (index, value) => {
        const updatedTreatments = [...dentist.treatments];
        updatedTreatments[index].name = value;
        setDentist({ ...dentist, treatments: updatedTreatments });
    };

    // Handle adding a new treatment
    const handleAddTreatment = () => {
        setDentist({
            ...dentist,
            treatments: [...dentist.treatments, { name: '' }]
        });
    };

    // Handle removing a treatment
    const handleRemoveTreatment = (index) => {
        const updatedTreatments = [...dentist.treatments];
        updatedTreatments.splice(index, 1);
        setDentist({ ...dentist, treatments: updatedTreatments });
    };

    const navigateToHome = () => {
        navigate(`/app/admin/dentistas`);
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        const hasChanges = JSON.stringify(dentist) !== JSON.stringify(originalDentist);

        if (!hasChanges) {
            message.info('No se detectaron cambios.');
            setIsEditing(false);
            return;
        }

        const visibleWorkingHours = dentist.workingHours.filter(hours => !hours.isHidden);

        for (const hours of visibleWorkingHours) {
            if (!hours.startTime || !hours.endTime) {
                message.error('Las horas de trabajo no pueden estar vacías.');
                return;
            }
            if (!isValidTime(hours.startTime) || !isValidTime(hours.endTime)) {
                message.error('El formato de la hora debe ser HH:mm.');
                return;
            }
            if (new Date(`1970-01-01T${hours.startTime}`) >= new Date(`1970-01-01T${hours.endTime}`)) {
                message.error('La hora de inicio no puede ser después de la hora de fin.');
                return;
            }
        }

        const groupedWorkingHours = visibleWorkingHours.reduce((acc, hours) => {
            acc[hours.day] = acc[hours.day] || [];
            acc[hours.day].push(hours);
            return acc;
        }, {});

        for (const day in groupedWorkingHours) {
            const dayHours = groupedWorkingHours[day];
            if (dayHours.length === 2) {
                const morning = dayHours[0];
                const afternoon = dayHours[1];
                if (new Date(`1970-01-01T${morning.endTime}`) > new Date('1970-01-01T12:00')) {
                    message.error('El horario de la mañana debe terminar antes de las 12:00.');
                    return;
                }
                if (new Date(`1970-01-01T${afternoon.startTime}`) <= new Date('1970-01-01T12:00')) {
                    message.error('El horario de la tarde debe comenzar después de las 12:00.');
                    return;
                }
            }
        }

        const formatDateString = (dateString) => {
            const [day, month, year] = dateString.split('-');
            return `${year}-${month}-${day}`;
        };

        const formattedVacations = dentist.vacations.map(vacation => {
            if (!vacation.startDate || !vacation.endDate) {
                message.error('Las fechas de vacaciones no pueden estar vacías.');
                return null;
            }
            const startDate = moment(formatDateString(vacation.startDate)).toISOString(true);
            const endDate = moment(formatDateString(vacation.endDate)).toISOString(true);
            console.log(startDate, endDate);

            if (startDate > endDate) {
                message.error('La fecha de inicio no puede ser después de la fecha de fin.');
                return null;
            }

            return {
                ...vacation,
                startDate: startDate,
                endDate: endDate
            };
        });

        if (formattedVacations.includes(null)) {
            return;
        }

        if (dentist.treatments.some(treatment => !treatment.name)) {
            message.error('Los tratamientos no pueden estar vacíos.');
            return;
        }

        if (dentist.NIE !== originalDentist.NIE) {
            if (!dentist.NIE) {
                message.error('El NIE no puede estar vacío.');
                return;
            }
        }
        if (dentist.username !== originalDentist.username) {
            if (!dentist.username) {
                message.error('El nombre no puede estar vacío.');
                return;
            }
        }

        if (dentist.email !== originalDentist.email) {
            if (!dentist.email) {
                message.error('El email no puede estar vacío.');
                return;
            }
        }

        const dentistToSave = {
            ...dentist,
            workingHours: visibleWorkingHours.map(hours => ({
                ...hours,
                startTime: `1970-01-01T${hours.startTime}:00.000Z`,
                endTime: `1970-01-01T${hours.endTime}:00.000Z`
            })),
            vacations: formattedVacations
        };

        try {
            const response = await updateUserByAdmin(
                id,
                dentistToSave.username,
                dentistToSave.email,
                dentistToSave.phoneNumber,
                dentistToSave.NIE,
                dentistToSave.workingHours,
                dentistToSave.treatments,
                dentistToSave.vacations
            );
            if (response.status === 200) {
                setOriginalDentist(JSON.parse(JSON.stringify(dentist)));
                setIsEditing(false);
                message.success('¡Detalles del dentista actualizados exitosamente!');
            } else {
                message.error('Error al actualizar los detalles del dentista.');
            }
        } catch (error) {
            console.error('Error updating user', id, ':', error);
            if (error.response) {
                const { status } = error.response;
                if (status === 400) {
                    message.error('Error de solicitud: datos inválidos.');
                } else if (status === 500) {
                    message.error('Error en el servidor: no se pudo completar la solicitud.');
                } else {
                    message.error('Error al actualizar los detalles del dentista.');
                }
            } else {
                message.error('Error al actualizar los detalles del dentista.');
            }
        }
    };

    const handleAddWorkingHour = (day) => {
        const newWorkingHours = {
            day: day,
            startTime: '',
            endTime: ''
        };
        setDentist((prevDentist) => ({
            ...prevDentist,
            workingHours: [...prevDentist.workingHours, newWorkingHours]
        }));
    };

    const handleRemoveWorkingHour = (day, index) => {
        let count = -1;
        const updatedWorkingHours = dentist.workingHours.map(hours => {
            if (hours.day === day && !hours.isHidden) {
                count++;
                if (count === index) {
                    return { ...hours, isHidden: true };
                }
            }
            return hours;
        });

        setDentist({
            ...dentist,
            workingHours: updatedWorkingHours
        });
    };

    // const handleAddTreatment = () => {
    //     setDentist((prevDentist) => ({
    //         ...prevDentist,
    //         treatments: [...prevDentist.treatments, { name: '' }]
    //     }));
    // };

    // const handleRemoveTreatment = (index) => {
    //     if (dentist.treatments.length > 1) {
    //         const updatedTreatments = dentist.treatments.filter((_, i) => i !== index);
    //         setDentist((prevDentist) => ({
    //             ...prevDentist,
    //             treatments: updatedTreatments
    //         }));
    //     } else {
    //         message.error('Debe haber al menos un tratamiento.');
    //     }
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDentist({
            ...dentist,
            [name]: value,
        });
    };

    const handleWorkingHoursChange = (day, index, value, type) => {
        let count = -1;
        const updatedWorkingHours = dentist.workingHours.map((hours) => {
            if (hours.day === day && !hours.isHidden) {
                count++;
                if (count === index) {
                    return { ...hours, [type]: value };
                }
            }
            return hours;
        });

        setDentist({
            ...dentist,
            workingHours: updatedWorkingHours,
        });
    };

    const handleAddVacation = () => {
        setDentist((prevDentist) => ({
            ...prevDentist,
            vacations: [...prevDentist.vacations, { startDate: '', endDate: '' }]
        }));
    };

    const handleRemoveVacation = (index) => {
        const updatedVacations = dentist.vacations.filter((_, i) => i !== index);
        setDentist((prevDentist) => ({
            ...prevDentist,
            vacations: updatedVacations
        }));
    };

    // const handleTreatmentChange = (index, e) => {
    //     const updatedTreatments = [...dentist.treatments];
    //     updatedTreatments[index].name = e.target.value;
    //     setDentist({
    //         ...dentist,
    //         treatments: updatedTreatments,
    //     });
    // };

    const handleVacationChange = (index, value, type) => {
        const updatedVacations = [...dentist.vacations];
        updatedVacations[index][type] = value;
        setDentist({
            ...dentist,
            vacations: updatedVacations,
        });
    };

    if (!dentist) {
        return <div>Loading...</div>;
    }

    const groupedWorkingHours = allDays.reduce((acc, day) => {
        acc[day] = dentist.workingHours.filter(hours => hours.day === day);
        return acc;
    }, {});

    const handleDeleteClick = async () => {
        try {
            const response = await deleteUser(id);
            message.success('Usuario eliminado exitosamente.');
            navigate('/app/admin/dentistas');
        } catch (error) {
            console.error('Error deleting user', id, ':', error);
            message.error('Error al eliminar el usuario.');
        }
    };

    const selectedCategories = dentist.treatments.map(treatment => treatment.name);

    return (
        <div className="bckg">
            <Row justify="center">
                <Col xl={14} lg={18} sm={22} xs={22} className="dentistcard">
                    <Row>
                        <Col span={24}>
                            <h1 className="home-title">Detalles del dentista</h1>
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col span={12} className="detail-title">ID:</Col>
                        <Col span={12}>
                            {isEditing ? (
                                <Input name="NIE" value={dentist.NIE} onChange={handleChange} />
                            ) : (
                                dentist.NIE
                            )}
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col span={12} className="detail-title">Usuario:</Col>
                        <Col span={12}>
                            {isEditing ? (
                                <Input name="username" value={dentist.username} onChange={handleChange} />
                            ) : (
                                dentist.username
                            )}
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col span={12} className="detail-title">Email:</Col>
                        <Col span={12}>
                            {isEditing ? (
                                <Input name="email" value={dentist.email} onChange={handleChange} />
                            ) : (
                                dentist.email
                            )}
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col span={12} className="detail-title">Telefono:</Col>
                        <Col span={12}>
                            {isEditing ? (
                                <Input name="phoneNumber" value={dentist.phoneNumber} onChange={handleChange} />
                            ) : (
                                dentist.phoneNumber
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="detail-title">Horas de trabajo:</Col>
                    </Row>
                    <Collapse>
                        <Panel header="Horas de trabajo">
                            <Collapse>
                                {Object.keys(groupedWorkingHours).map((day, dayIndex) => (
                                    <Panel header={daysOfWeekInSpanish[day]} key={dayIndex}>
                                        {groupedWorkingHours[day].filter(hours => !hours.isHidden).length > 0 ? (
                                            groupedWorkingHours[day]
                                                .filter(hours => !hours.isHidden) // Filtrar los horarios ocultos
                                                .map((hours, idx) => (
                                                    <Row key={`${day}-${idx}`}>
                                                        <Col span={24}>
                                                            {isEditing ? (
                                                                <>
                                                                    <span>
                                                                        {groupedWorkingHours[day].filter(hours => !hours.isHidden).length === 1
                                                                            ? 'Horario único: '
                                                                            : (idx === 0 ? 'Mañana: ' : 'Tarde: ')
                                                                        }
                                                                    </span>
                                                                    <Input
                                                                        value={hours.startTime}
                                                                        onChange={(e) => handleWorkingHoursChange(day, idx, e.target.value, 'startTime')}
                                                                        placeholder="HH:mm"
                                                                    />
                                                                    {' - '}
                                                                    <Input
                                                                        value={hours.endTime}
                                                                        onChange={(e) => handleWorkingHoursChange(day, idx, e.target.value, 'endTime')}
                                                                        placeholder="HH:mm"
                                                                    />
                                                                    <Button
                                                                        type="link"
                                                                        onClick={() => handleRemoveWorkingHour(day, idx)}
                                                                    >
                                                                        Eliminar
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                `${hours.startTime} - ${hours.endTime}`
                                                            )}
                                                        </Col>
                                                    </Row>
                                                ))
                                        ) : (
                                            <Row>
                                                <Col span={24}>
                                                    No hay horarios para este día.
                                                </Col>
                                            </Row>
                                        )}
                                        {isEditing && groupedWorkingHours[day].filter(hours => !hours.isHidden).length < 2 && (
                                            <Button
                                                type="link"
                                                onClick={() => handleAddWorkingHour(day)}
                                            >
                                                Añadir horario
                                            </Button>
                                        )}
                                    </Panel>
                                ))}
                            </Collapse>
                        </Panel>
                    </Collapse>
                    <Row>
                        <Col span={24} className="detail-title">Tratamientos:</Col>
                    </Row>
                    <Collapse className='lastcollapse'>
                        <Panel header="Tratamientos">
                            {dentist.treatments && dentist.treatments.length > 0 ? (
                                dentist.treatments.map((treatment, index) => (
                                    <Row key={index} className="detail-row">
                                        <Col span={24}>
                                            {isEditing ? (
                                                <>
                                                    <Select
                                                        value={treatment.name}
                                                        onChange={(value) => handleTreatmentChange(index, value)}
                                                        placeholder="Selecciona un tratamiento"
                                                        style={{ width: '100%' }}
                                                    >
                                                        {getUniqueCategories().map((category) => (
                                                            <Option
                                                                key={category}
                                                                value={category}
                                                                disabled={selectedCategories.includes(category)}
                                                            >
                                                                {category}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                    <Button
                                                        type="link"
                                                        onClick={() => handleRemoveTreatment(index)}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </>
                                            ) : (
                                                treatment.name
                                            )}
                                        </Col>
                                    </Row>
                                ))
                            ) : (
                                <Row>
                                    <Col span={24}>No hay tratamientos registrados.</Col>
                                </Row>
                            )}
                            {isEditing && (
                                <Button
                                    type="link"
                                    onClick={handleAddTreatment}
                                >
                                    Añadir tratamiento
                                </Button>
                            )}
                        </Panel>
                    </Collapse>
                    <Row>
                        <Col span={24} className="detail-title">Vacaciones:</Col>
                    </Row>
                    <Collapse className='lastcollapse'>
                        <Panel header="Vacaciones">
                            {dentist.vacations && dentist.vacations.length > 0 ? (
                                dentist.vacations.map((vacation, index) => (
                                    <Row key={index} className="detail-row">
                                        <Col span={24}>
                                            {isEditing ? (
                                                <>
                                                    <Input
                                                        name={`vacations[${index}].startDate`}
                                                        value={vacation.startDate}
                                                        onChange={(e) => handleVacationChange(index, e.target.value, 'startDate')}
                                                        placeholder="dd-mm-yyyy"
                                                    />
                                                    {' - '}
                                                    <Input
                                                        name={`vacations[${index}].endDate`}
                                                        value={vacation.endDate}
                                                        onChange={(e) => handleVacationChange(index, e.target.value, 'endDate')}
                                                        placeholder="dd-mm-yyyy"
                                                    />
                                                    <Button
                                                        type="link"
                                                        onClick={() => handleRemoveVacation(index)}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </>
                                            ) : (
                                                `${vacation.startDate} - ${vacation.endDate}`
                                            )}
                                        </Col>
                                    </Row>
                                ))
                            ) : (
                                <Row>
                                    <Col span={24}>No hay vacaciones registradas.</Col>
                                </Row>
                            )}
                            {isEditing && (
                                <Button
                                    type="link"
                                    onClick={handleAddVacation}
                                >
                                    Añadir vacaciones
                                </Button>
                            )}
                        </Panel>
                    </Collapse>
                    <Row className='lastrow'>
                        <Col span={24}>
                            {isEditing ? (
                                <Row>
                                    <Button className="saveButton lastcollapse" onClick={handleSaveClick}>
                                        GUARDAR
                                    </Button>
                                    <Popconfirm
                                        title="¿Estás seguro de que quieres eliminar este usuario?"
                                        onConfirm={handleDeleteClick}
                                        okText="Sí"
                                        cancelText="No"
                                    >
                                        <Button type="danger" className='delete'>
                                            Eliminar Usuario
                                        </Button>
                                    </Popconfirm>
                                </Row>
                            ) : (
                                <Button className="editButton lastcollapse" onClick={handleEditClick}>
                                    EDITAR
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row className='lastrow'>
                        <Col span={24}>
                            <Button className="volverButton3" onClick={navigateToHome}>
                                VOLVER
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default IndividualDentistManage;