import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getIncomes } from '../services/AppointmentServices';
import '../assets/styles/Contaduria.css';
import moment from 'moment';

const Ingresos = () => {
    const [incomes, setIncomes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIncomes = async () => {
            try {
                const data = await getIncomes();
                setIncomes(data.incomes || []); // Asegúrate de que `data.incomes` esté disponible
            } catch (error) {
                console.error('Error loading incomes:', error.message);
            }
        };

        fetchIncomes();
    }, []);

    const handleBack = () => {
        navigate('/app/admin/contaduria');
    };

    return (
        <div className="home-client">
            <Row justify="center" className="cardsRow">
                <Col xl={16} lg={22} sm={22} xs={22}>
                    <Row justify="center">
                        <Col span={24} className="carddd2">
                            <Row justify="center">
                                <Col span={24} className='alinear'>
                                    <span className='textttt'>Ingresos</span>
                                </Col>
                                <Col span={18} className='income2'>
                                    {incomes.map((income, index) => (
                                        <div key={index} className="income-item">
                                            {/* <p><strong>Ingreso {index + 1}</strong></p> */}
                                            <p><strong>Monto Total:</strong> {income.confirmedBudget.montoTotal}</p>
                                            <p><strong>Tratamientos:</strong></p>
                                            <ul>
                                                {income.confirmedBudget.tratamientos.map((treatment, tIndex) => (
                                                    <li key={tIndex}>
                                                        {`Diente ${treatment.diente}: ${treatment.tratamiento} - $${treatment.precio}`}
                                                    </li>
                                                ))}
                                            </ul>
                                            <p><strong>Día:</strong> {income.date.day}</p>
                                            <p><strong>Horario:</strong> {moment.utc(income.date.start_time).format('HH:mm')} - {moment.utc(income.date.end_time).format('HH:mm')}</p>
                                            <hr className="divider2" />
                                        </div>
                                    ))}
                                </Col>
                                <Col span={24} className="alinear separar-top">
                                    <Button type="primary" className="buttonlogin" onClick={handleBack}>
                                        Volver
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Ingresos;
