import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, message, Row, Select} from 'antd';
import moment from 'moment';
import {useNavigate, useParams} from 'react-router-dom';
import {getDentistas} from '../services/UserServices';
import {getAvailableHours, registerAppointment} from '../services/AppointmentServices';
import {getProcedures} from '../services/ProceduresServices';
import '../assets/styles/Agendar.css';

const {Option} = Select;

const DarCitaConUser = () => {
    const [horariosDisponibles, setHorariosDisponibles] = useState([]);
    const [tratamientoSeleccionado, setTratamientoSeleccionado] = useState('');
    const [especialistaSeleccionado, setEspecialistaSeleccionado] = useState('');
    const [especialistaId, setEspecialistaId] = useState('');
    const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
    const [horarioSeleccionado, setHorarioSeleccionado] = useState('');
    const [formValido, setFormValido] = useState(false);
    const [placeholderDate, setPlaceholderDate] = useState('Seleccione la fecha');
    const [placeholderTime, setPlaceholderTime] = useState('Seleccione la hora');
    const [dentistas, setDentistas] = useState([]);
    const [filteredDentistas, setFilteredDentistas] = useState([]);
    const [tratamientos, setTratamientos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const {id} = useParams();
    const clienteId = id;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDentists = async () => {
            try {
                const data = await getDentistas();
                setDentistas(data);
            } catch (error) {
                console.error('Error fetching dentists:', error);
            }
        };

        fetchDentists();
    }, []);

    useEffect(() => {
        const fetchTreatments = async () => {
            try {
                const data = await getProcedures();

                if (Array.isArray(data.procedures)) {
                    const uniqueCategories = Array.from(new Set(data.procedures.map(tratamiento => tratamiento.categoria)));
                    setCategorias(uniqueCategories);
                    setTratamientos(data.procedures);
                } else {
                    console.error('Formato de datos inesperado:', data);
                }
            } catch (error) {
                console.error('Error fetching treatments:', error);
                message.error('Error al cargar tratamientos. Por favor, intente nuevamente más tarde.');
                setCategorias([]);
                setTratamientos([]);
            }
        };

        fetchTreatments();
    }, []);

    useEffect(() => {
        const isValid = validarFormulario();
        setFormValido(isValid);
    }, [tratamientoSeleccionado, especialistaSeleccionado, fechaSeleccionada, horarioSeleccionado]);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 630) {
                setPlaceholderDate('Fecha');
                setPlaceholderTime('Hora');
            } else {
                setPlaceholderDate('Seleccione la fecha');
                setPlaceholderTime('Seleccione la hora');
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const generarHorariosDisponibles = async (specialistId, date) => {
        try {
            const availableHours = await getAvailableHours(specialistId, date);

            const formattedAvailableHours = availableHours.map(times => {
                const [start, end] = times;
                return `${start} - ${end}`;
            });

            setHorariosDisponibles(formattedAvailableHours);
        } catch (error) {
            console.error('Error fetching working hours:', error);
            message.error('Error al cargar horarios disponibles.');
            setHorariosDisponibles([]);
        }
    };

    const validarFormulario = () => {
        return tratamientoSeleccionado && especialistaSeleccionado && fechaSeleccionada && horarioSeleccionado;
    };

    const disabledDate = (current) => {
        const today = moment().startOf('day');
        const twoMonthsFromToday = moment().add(2, 'months').endOf('day');
        return current && (current < today || current > twoMonthsFromToday || current.day() === 0);
    };

    const handleTratamientoChange = (value) => {
        setTratamientoSeleccionado(value);
        const filtered = dentistas.filter(dentista =>
            Array.isArray(dentista.treatments) &&
            dentista.treatments.some(treatment => treatment.name === value)
        );
        setFilteredDentistas(filtered);
        setEspecialistaSeleccionado('');
        setEspecialistaId('');
        setFechaSeleccionada(null);
        setHorarioSeleccionado('');
        setHorariosDisponibles([]);
    };

    const handleEspecialistaChange = async (value) => {
        setEspecialistaSeleccionado(value);
    };

    const handleDateChange = async (date) => {
        setFechaSeleccionada(date);
        setHorarioSeleccionado('');
        setHorariosDisponibles([]);

        if (date && especialistaSeleccionado) {
            try {
                await generarHorariosDisponibles(especialistaSeleccionado, date.format('YYYY-MM-DD'));
            } catch (error) {
                console.error('Error fetching working hours:', error);
                message.error('No hay horarios disponibles para ese día');
                setHorariosDisponibles([]);
            }
        }
    };

    const handleAgendarClick = async () => {
        if (formValido) {
            try {
                await registerAppointment(tratamientoSeleccionado, especialistaSeleccionado, fechaSeleccionada.format('YYYY-MM-DD'), horarioSeleccionado, clienteId);
                message.success('Cita registrada con éxito');
                setTratamientoSeleccionado('');
                setEspecialistaSeleccionado('');
                setEspecialistaId('');
                setFechaSeleccionada(null);
                setHorarioSeleccionado('');
                setHorariosDisponibles([]);
            } catch (error) {
                message.error('Error al registrar la cita');
                console.error('Error registering appointment:', error);
            }
        } else {
            message.warning('Por favor complete todos los campos');
        }
    };

    const handleBack = () => {
        navigate('/app/secretaria/verificar');
    };

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={8} sm={12} xs={16} className="agendarCard">
                    <Row>
                        <h1 className="home-title">Agenda aquí tu próxima cita</h1>
                        <p className="under-title">Ingresa toda la información requerida para poder obtener tu cita a continuación.</p>
                    </Row>
                    <Row className="inputsRow">
                        <Col span={24} className="inputCol input-group">
                            <Select
                                showSearch
                                placeholder="Tratamiento"
                                className="selectInput"
                                value={tratamientoSeleccionado || undefined}
                                onChange={handleTratamientoChange}
                            >
                                {categorias.map(categoria => (
                                    <Option key={categoria} value={categoria}>
                                        {categoria}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={24} className="inputCol">
                            <Select
                                showSearch
                                placeholder="Especialista"
                                className="selectInput"
                                value={especialistaSeleccionado || undefined}
                                onChange={handleEspecialistaChange}
                            >
                                {filteredDentistas.map(dentista => (
                                    <Option key={dentista._id} value={dentista._id}>{dentista.username}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={24} className="inputCol">
                            <DatePicker
                                placeholder={placeholderDate}
                                className="selectInput"
                                value={fechaSeleccionada || undefined}
                                onChange={handleDateChange}
                                disabledDate={disabledDate}
                            />
                        </Col>
                        <Col span={24} className="inputCol">
                            <Select
                                placeholder={placeholderTime}
                                className="selectInput"
                                disabled={horariosDisponibles.length === 0 || !fechaSeleccionada}
                                value={horarioSeleccionado}
                                onChange={(value) => setHorarioSeleccionado(value)}
                            >
                                {horariosDisponibles.map((horario, index) => (
                                    <Option key={index} value={horario}>
                                        {horario}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={24} className="inputCol">
                            <Button className="greenButton padright" onClick={handleBack}>
                                Volver
                            </Button>
                            <Button className="agendarButton" disabled={!formValido} onClick={handleAgendarClick}>
                                AGENDAR
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default DarCitaConUser;
