import React, {useEffect, useRef, useState} from 'react';
import {Button, Carousel, Col, Row} from 'antd';
import {
    CalendarOutlined,
    CrownOutlined,
    ExperimentOutlined,
    LeftOutlined,
    MedicineBoxOutlined,
    RightOutlined,
    ScheduleOutlined,
    ScissorOutlined,
    SmileOutlined,
    ToolOutlined,
    ClearOutlined
} from '@ant-design/icons';
import img1 from '../assets/images/slider.webp';
import tooth from '../assets/images/tooth.svg';
import protesis from '../assets/images/protesis.svg';
import whitening from '../assets/images/whitening.svg';
import bracket from '../assets/images/bracket.svg';
import img2 from '../assets/images/slider2.webp';
import img3 from '../assets/images/slider3.webp';
import '../assets/styles/MainPage.css';
import video from '../assets/videos/video.mp4';
import {useNavigate} from 'react-router-dom';
import {useAuthCookie} from '../utils/Cookies';

const MainPage = () => {
    const carouselRef = useRef();
    const [customerCounts, setCustomerCounts] = useState([0, 0, 0]);
    const navigate = useNavigate();
    const isAuthenticated = useAuthCookie();

    useEffect(() => {
        const startCountUp = (targetIndex, startValue, endValue, duration) => {
            let current = startValue;
            const increment = (endValue - startValue) / (duration / 10);
            const timer = setInterval(() => {
                current += increment;
                if (current >= endValue) {
                    clearInterval(timer);
                    current = endValue;
                }
                setCustomerCounts(prevCounts => {
                    const updatedCounts = [...prevCounts];
                    updatedCounts[targetIndex] = current;
                    return updatedCounts;
                });
            }, 10);
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    startCountUp(0, 0, 500, 1000);
                    startCountUp(1, 0, 5, 1000);
                    startCountUp(2, 0, 40, 1000);
                    observer.unobserve(entry.target);
                }
            });
        });

        observer.observe(document.getElementById('counter'));
    }, []);

    const goToNext = () => {
        carouselRef.current.next();
    };

    const goToPrev = () => {
        carouselRef.current.prev();
    };

    const handleAppointmentClick = () => {
        if (isAuthenticated) {
            navigate('/app');
        } else {
            navigate('/login');
        }
    };

    return (
        <body>
        <div className="custom-carousel-container" id="inicio">
            <Carousel effect="fade" dots={false} autoplay={true} autoplaySpeed={3000} ref={carouselRef}>
                <div className="slidediv">
                    <img src={img1} alt="Img1" className="slide"/>
                </div>
                <div>
                    <img src={img2} alt="Img2" className="slide"/>
                </div>
                <div>
                    <img src={img3} alt="Img3" className="slide"/>
                </div>
            </Carousel>
            <div className="custom-arrows">
                <LeftOutlined className="custom-arrow prev" onClick={goToPrev}/>
                <RightOutlined className="custom-arrow next" onClick={goToNext}/>
            </div>
            <div className="custom-text">
                <div>
                    <span className="titletext">Experimenta la <span className="purple">Excelencia</span> en Cuidado Dental</span>
                    <p className="subtitletext">Tu sonrisa merece lo mejor, Descubre cómo nuestro equipo especializado y
                        atención personalizada están transformando el cuidado dental.</p>
                    <Button type="primary" className="buttons" onClick={handleAppointmentClick}>
                        Obtener Cita
                    </Button>
                </div>
            </div>
        </div>
        <Row className="conocenos-row">
            <Col span={24} className="conocenos-col">
                <span className="title">Conocenos</span>
            </Col>
            <Col xl={9} md={10} xs={20} className="quienes-col">
                <span className="bienvenido">¡Bienvenido a SmileUp!</span>
                <p className="aboutus">
                    En nuestro consultorio dental, nos dedicamos a proporcionarte una atención excepcional
                    y personalizada para que puedas mantener una sonrisa saludable y radiante. Con un equipo
                    comprometido y tecnología de vanguardia, estamos aquí para brindarte la mejor experiencia
                    odontológica posible.
                </p>
                <p className="aboutus">
                    Únete a nuestra familia dental y descubre el cuidado que te mereces en SmileUp.
                </p>
            </Col>
            <Col xl={9} md={10} xs={20} className="video-col">
                <video className="video" controls>
                    <source src={video} type="video/mp4"/>
                </video>
            </Col>
        </Row>
        <Row className="stats-row-img">
            <Row className="color">
                <Col xl={5} lg={6} md={8} xs={24}>
                    <Row className="alignment">
                        <Col md={6} sm={3}>
                            <a className="circle-stat"><SmileOutlined className="iconsize-stat"/></a>
                        </Col>
                        <Col md={18} sm={7}>
                            <span className="number" id="counter">+{customerCounts[0].toFixed(0)}</span>
                            <p className="descrip">Clientes Satisfechos</p>
                        </Col>
                    </Row>
                </Col>
                <Col xl={5} lg={6} md={8} xs={24}>
                    <Row className="alignment">
                        <Col md={6} sm={3}>
                            <a className="circle-stat"><CalendarOutlined className="iconsize-stat"/></a>
                        </Col>
                        <Col md={18} sm={7}>
                            <span className="number" id="counter">+{customerCounts[1].toFixed(0)}</span>
                            <p className="descrip">Años De Experiencia</p>
                        </Col>
                    </Row>
                </Col>
                <Col xl={5} lg={6} md={8} xs={24}>
                    <Row className="alignment">
                        <Col md={6} sm={3}>
                            <a className="circle-stat"><ScheduleOutlined className="iconsize-stat"/></a>
                        </Col>
                        <Col md={18} sm={7}>
                            <span className="number" id="counter">+{customerCounts[2].toFixed(0)}</span>
                            <p className="descrip">Tratamientos Por Mes</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Row>
        <Row className="servicios-row" id="servicios">
            <Col span={24} className="servicios-col">
                <span className="title">Algunos de Nuestros Servicios</span>
            </Col>
            <Col md={7} xs={18}>
                <Row className="service-row limpieza">
                    <Col span={4}><ClearOutlined  className="serviceicon"/></Col>
                    <Col span={20} className="">
                        <span className="service-title">Limpieza</span>
                        <p className="service-subtitle">Esencial para mantener una buena salud bucal. Nuestros
                            profesionales eliminan la placa y el sarro, lo que ayuda a prevenir enfermedades de las
                            encías y caries.</p>
                    </Col>
                </Row>
                <Row className="service-row blanqueamiento">
                    <Col span={4}><img src={whitening} className="serviceicon importedsvg"/></Col>
                    <Col span={20}>
                        <span className="service-title">Blanqueamiento</span>
                        <p className="service-subtitle">Nuestro tratamiento de blanqueamiento dental puede mejorar la
                            apariencia de tus dientes, eliminando manchas y decoloraciones. Te ayudamos a lucir una
                            sonrisa más brillante y radiante.</p>
                    </Col>
                </Row>
            </Col>
            <Col md={7} xs={18}>
                <Row className="service-row">
                    <Col span={4}><img src={tooth} alt="Implantología" className="serviceicon importedsvg" /></Col>
                    <Col span={20}>
                        <span className="service-title">Implantología</span>
                        <p className="service-subtitle">Ofrecemos soluciones de implantología dental avanzadas para
                            reemplazar dientes perdidos. Los implantes dentales son una opción duradera y natural que
                            restaura la funcionalidad y estética de tu sonrisa.</p>
                    </Col>
                </Row>
                <Row className="service-row">
                    <Col span={4}><img src={protesis} className="serviceicon importedsvg"/></Col>
                    <Col span={20}>
                        <span className="service-title">Prótesis</span>
                        <p className="service-subtitle">Nuestras prótesis dentales personalizadas pueden restaurar la
                            función y la apariencia de los dientes perdidos. Utilizamos materiales de alta calidad para
                            crear prótesis duraderas y cómodas.</p>
                    </Col>
                </Row>
            </Col>
            <Col md={7} xs={18}>
                <Row className="service-row">
                    <Col span={4}><ScissorOutlined className="serviceicon"/></Col>
                    <Col span={20}>
                        <span className="service-title">Cirugías</span>
                        <p className="service-subtitle">Realizamos una variedad de procedimientos quirúrgicos dentales,
                            desde extracciones de dientes de sabiduría hasta injertos óseos. Nuestro equipo altamente
                            capacitado garantiza la seguridad y comodidad del paciente durante todo el proceso.</p>
                    </Col>
                </Row>
                <Row className="service-row">
                    <Col span={4}><img src={bracket} className="serviceicon importedsvg"/></Col>
                    <Col span={20}>
                        <span className="service-title">Ortodoncia fija y alineadores</span>
                        <p className="service-subtitle">Ofrecemos tratamientos de ortodoncia avanzados para corregir
                            problemas de alineación dental. Desde brackets tradicionales hasta alineadores
                            transparentes, tenemos opciones para todas las edades y necesidades.</p>
                    </Col>
                </Row>
            </Col>
        </Row>;
        </body>
    );
};
export default MainPage;