import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Divider } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getAnamnesisByAppointmentId } from '../services/AppointmentServices';
import { useUserRole } from '../utils/Cookies';
import '../assets/styles/AnamnesisCreation.css';

const responseMapping = {
    gumsBleed: { 1: 'Sí', 2: 'No', 3: 'No sé' },
    teethMobility: { 1: 'Sí', 2: 'No', 3: 'No sé' },
    teethColorSatisfaction: { 1: 'Sí', 2: 'No' },
    sensitivity: { 1: 'Sí', 2: 'No' },
    surgery: { 1: 'Sí', 2: 'No' },
    alcohol: { 1: 'Sí', 2: 'No' },
    smoker: { 1: 'Sí', 2: 'No' },
    diabetes: { 1: 'Sí', 2: 'No' },
    hypertension: { 1: 'Sí', 2: 'No' },
    bruxism: { 1: 'Sí', 2: 'No', 3: 'No sé' },
    pregnant: { 1: 'Sí', 2: 'No' },
    lactating: { 1: 'Sí', 2: 'No' }
};

const AnamnesisInspect = () => {
    const { id: appointmentId } = useParams();
    const [anamnesisData, setAnamnesisData] = useState({});
    const navigate = useNavigate();
    const role = useUserRole();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAnamnesisByAppointmentId(appointmentId);
                if (data) {
                    setAnamnesisData(data);
                }
            } catch (error) {
                console.error('Error fetching anamnesis data:', error);
            }
        };

        fetchData();
    }, [appointmentId]);

    const renderData = (label, value, mapping) => (
        <Row gutter={[16, 16]} className="data-row">
            <Col span={24}>
                <label className="data-label">{label}</label>
                <p className="data-value">{value !== null && value !== undefined ? (mapping ? mapping[value] : value.toString()) : 'vacío'}</p>
            </Col>
        </Row>
    );

    const renderDate = (label, date) => (
        <Row gutter={[16, 16]} className="data-row">
            <Col span={24}>
                <label className="data-label">{label}</label>
                <p className="data-value">{date ? moment(date).format('YYYY-MM-DD') : 'vacío'}</p>
            </Col>
        </Row>
    );

    return (
        <div className="bckg">
            <Row justify="center">
                <Col lg={8} sm={12} xs={16} className="anamnesisCard">
                    <Row>
                        <h2>Anamnesis</h2>
                    </Row>
                    {renderData('Motivo', anamnesisData.reason)}
                    {renderData('Sus encías suelen sangrar?', anamnesisData.gumsBleed, responseMapping.gumsBleed)}
                    {renderData('Sus dientes tienen movilidad?', anamnesisData.teethMobility, responseMapping.teethMobility)}
                    {renderData('Está satisfecho con el color de tus dientes?', anamnesisData.teethColorSatisfaction, responseMapping.teethColorSatisfaction)}
                    {renderData('Tiene sensibilidad?', anamnesisData.sensitivity, responseMapping.sensitivity)}
                    {renderData('Alergia a alguna comida o medicamento o anestesia?', anamnesisData.allergyDetail)}
                    {renderData('Está usando alguna medicación?', anamnesisData.medicationDetail)}
                    <Divider className="custom-divider" />
                    {renderData('Ya hizo alguna cirugía?', anamnesisData.surgery, responseMapping.surgery)}
                    {renderData('Alcohol?', anamnesisData.alcohol, responseMapping.alcohol)}
                    {renderData('Fumante?', anamnesisData.smoker, responseMapping.smoker)}
                    <Divider className="custom-divider" />
                    {renderDate('Ultima visita al médico?', anamnesisData.lastDoctorVisit)}
                    {renderDate('Ultima visita al dentista?', anamnesisData.lastDentistVisit)}
                    {renderData('Diabetes?', anamnesisData.diabetes, responseMapping.diabetes)}
                    {renderData('Hipertensión?', anamnesisData.hypertension, responseMapping.hypertension)}
                    {renderData('Bruxismo?', anamnesisData.bruxism, responseMapping.bruxism)}
                    <Divider className="custom-divider" />
                    {renderData('Embarazada?', anamnesisData.pregnant, responseMapping.pregnant)}
                    {renderData('En período de lactancia?', anamnesisData.lactating, responseMapping.lactating)}
                    <Row justify="center" className="button-row">
                        <Button className='agendarButton' onClick={() => navigate(`/app/${role}/historial`)}>Volver</Button>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AnamnesisInspect;
